:root {
}

.new-vendor-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.new-vendor-container .new-vendor-body {
  width: 100%;
  display: flex;
}

.new-vendor-container .new-vendor-body .vendor-form-div {
  width: 75%;
  margin: auto;
}

.new-vendor-container .new-vendor-body .vendor-form-div .main-title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
  margin-bottom: var(--title-margin-bottom);
  text-align: start;
}

.new-vendor-container .new-vendor-body .vendor-form-div .error-message {
  margin-top: 1rem;
  color: var(--inside-danger-color);
  text-align: center;
}

.new-vendor-container .new-vendor-body .loader {
  margin-top: 6rem;
}

@media only screen and (max-width: 1500px) {
  .new-vendor-container .new-vendor-body .side-container {
    width: 20%;
  }

  .new-vendor-container .new-vendor-body .middle-container {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .new-vendor-container .new-vendor-body .middle-container {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  .new-vendor-container {
    padding: 0rem 0.75rem;
  }

  .new-vendor-container .new-vendor-body .middle-container {
    width: 100%;
  }

  .new-vendor-container .new-vendor-body .vendor-form-div {
    width: 100%;
  }
}
