.deposit-body .deposit-bank-div {
}

.deposit-body .section .value-input-pix {
  padding: 0rem;
}

.deposit-body .pix-div {
  display: flex;
  flex-direction: column;
}

.deposit-body .deposit-bank-div .loading-keys {
  margin-top: 1rem !important;
  width: 2rem !important;
  height: 2rem !important;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
