:root {
}

.payment-options-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.payment-options-body {
  width: 100%;
  display: flex;
}

.payment-options-body .payment-options-inside-div {
  width: 75%;
  margin: auto;
}

.payment-options-body .main-title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
  margin-bottom: var(--title-margin-bottom);
  text-align: start;
}

.payment-options-body .payment-option-container {
  border-radius: var(--div-border-radius);
  overflow: auto;
  margin: auto;
  animation: fade-in-up 0.6s;
}

.payment-options-body .payment-option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: var(--div-border-radius);
  align-items: center;
  overflow: auto;
  color: var(--inside-div-text-color);
}

.payment-options-body .payment-option ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.payment-options-body .payment-option ::-webkit-scrollbar-thumb {
  background: #888;
}

.payment-options-body .payment-option ::-webkit-scrollbar-track {
  background: white;
}

.payment-options-body .payment-option .payment-option-row-container {
  width: 100%;
  border-radius: var(--div-border-radius);
  cursor: pointer;
  margin-bottom: 1rem;
  color: var(--inside-div-text-color);
  text-decoration: none;
}

.payment-options-body .payment-option .payment-option-row-container:hover {
  box-shadow: var(--div-shadow-border-hover);
}

.payment-options-body .payment-option .payment-option-row {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-options-body .payment-option .payment-option-row .metadata-column {
  width: calc(100% - var(--arrow-icon-size));
  display: flex;
  justify-content: start;
  align-items: center;
}

.payment-options-body
  .payment-option
  .payment-option-row
  .metadata-column
  .icon {
  font-size: calc(1.85rem * var(--font-size-scale)) !important;
  margin-right: 0.75rem;
  color: var(--inside-highlight-color);
}

.payment-options-body
  .payment-option
  .payment-option-row
  .metadata-column
  .text {
  width: 90%;
}

.payment-options-body
  .payment-option
  .payment-option-row
  .metadata-column
  .text
  .title {
  font-size: calc(0.95rem * var(--font-size-scale));
  font-weight: var(--font-weight-3);
  text-align: start;
}

.payment-options-body
  .payment-option
  .payment-option-row
  .metadata-column
  .text
  .description {
  font-size: calc(0.9rem * var(--font-size-scale));
  font-style: italic;
  text-align: start;
  opacity: var(--opacity-2);
}

.payment-options-body .payment-option .payment-option-row .arrow {
  color: var(--inside-div-text-color);
  font-size: calc(var(--arrow-icon-size) * var(--font-size-scale)) !important;
}

@media only screen and (max-width: 1500px) {
  .payment-options-body .payment-options-inside-div {
    width: 100%;
  }

  .payment-options-body .payment-option .payment-option-row {
    height: 5.5rem;
  }
}

@media only screen and (max-width: 1300px) {
  .payment-options-body .payment-option .payment-option-row {
    height: 5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .payment-options-body {
    width: 100%;
  }

  .payment-options-container .payment-options-body .middle-container {
    width: 100%;
  }

  .payment-options-body .payment-options-inside-div {
    width: 100%;
  }

  .payment-options-body .main-title {
  }

  .payment-options-body .payment-option .payment-option-row-container {
    padding: 0rem;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .payment-options-body {
    width: 100%;
  }

  .payment-options-container .payment-options-body .middle-container {
    width: 100%;
  }

  .payment-options-body .payment-options-inside-div {
    width: 100%;
  }

  .payment-options-body .main-title {
  }

  .payment-options-body .payment-option .payment-option-row-container {
    padding: 0rem;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
