.pass-code-container {
  min-height: 120vh;
  width: 100%;
  display: flex;
}

.pass-code-container .login-2fa-div {
  height: 40rem;
}

.pass-code-container .login-2fa-div .login-div-icon {
  font-size: calc(1.5rem * var(--font-size-scale)) !important;
  position: absolute;
}

.pass-code-container .login-2fa-div .pass-code-image {
  width: var(--logo-login-width);
  aspect-ratio: var(--logo-aspect-ratio);
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.pass-code-container p {
  margin-top: 0.75rem;
  margin-bottom: 0px;
  font-size: calc(0.9rem * var(--font-size-scale));
  color: var(--inside-div-text-color);
  text-align: center;
}

.pass-code-container .bottom-section {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
}

.pass-code-form-div {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

.react-code-input {
  margin-top: 2rem;
}

.react-code-input input {
  font-size: calc(1.75rem * var(--font-size-scale)) !important;
}

/*
.react-code-input input {
  width: 2rem !important;
  height: 2.35rem !important;
}*/

.pass-code-container .aditional-information {
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.pass-code-container .aditional-information .links {
  font-weight: bolder;
  text-decoration: underline;
  cursor: pointer;
}

.pass-code-container .aditional-information .inside {
  color: var(--inside-div-text-color);
}

.pass-code-container .aditional-information .outside {
  color: var(--outside-div-text-color);
}

.pass-code-container .resend-message {
  text-align: start;
  margin-top: 1rem;
  font-size: 0.8rem;
}

.pass-code-container .resend-message .links {
  font-weight: bolder;
  text-decoration: underline;
  cursor: pointer;
}

.pass-code-container .resend-message .inside {
  color: var(--inside-div-text-color);
}

.pass-code-container .resend-message .outside {
  color: var(--outside-div-text-color);
}

.pass-code-container .resend-message .open-modal-gauth-link {
  width: fit-content;
  font-weight: bold;
  text-decoration: underline;
  color: var(--inside-highlight-color);
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.pass-code-container .login-2fa-div .qrcode {
  margin: auto;
  width: 15rem;
  height: 15rem;
}

.pass-code-container .login-2fa-div .key-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pass-code-container .login-2fa-div .key-div .key {
  display: flex;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

.pass-code-container .login-2fa-div .key-div .key .copy-key-icon {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}

.pass-code-container .login-2fa-div .key-div .key .copy-success-icon {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  color: var(--success-color);
  animation: animate-fade 0.25s;
}

.skip-button {
  border: none !important;
  background: none !important;
  color: var(--dull-color) !important;
  font-weight: bold;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.pass-code-container .select-form {
  margin-top: 3rem;
}

.pass-code-container .buttons-div .secondary-button {
  width: fit-content;
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .pass-code-container .login-2fa-div .register-title {
    color: var(--outside-div-text-color);
  }

  .pass-code-container .login-2fa-div .register-subtitle {
    color: var(--outside-div-text-color);
  }

  .pass-code-container p {
    color: var(--outside-div-text-color);
    margin-bottom: 0.75rem;
  }

  .resend-message b {
    color: var(--outside-div-text-color);
  }

  .react-code-input input {
    width: 2rem !important;
    height: 2.35rem !important;
  }
}

.get-google-authenticator-title-div {
  display: flex;
  align-items: center;
}

.get-google-authenticator-title-div .google-icon {
  font-size: 2rem;
  margin-right: 0.25rem;
}

.download-options-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.download-options-div .download-option {
  display: flex;
  cursor: pointer;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  width: 80%;
  height: 4.5rem;
  align-items: center;
  justify-content: start !important;
}

.download-options-div .first-option {
  margin-bottom: 0.5rem;
}

.download-options-div .download-option .store-icon {
  font-size: 2rem;

  margin-right: 0.25rem;
}

@media only screen and (max-width: 1500px) {
}
@media only screen and (max-width: 1200px) {
  .pass-code-container .login-2fa-div .register-title {
    color: var(--outside-div-text-color);
  }

  .pass-code-container .login-2fa-div .register-subtitle {
    color: var(--outside-div-text-color);
  }

  .pass-code-container p {
    color: var(--outside-div-text-color);
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
  }

  .resend-message b {
    color: var(--outside-div-text-color);
  }
}

@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
  .react-code-input input {
    width: 2rem !important;
    height: 2.35rem !important;
  }

  .pass-code-container p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .react-code-input input {
  }
}

@media only screen and (max-width: 320px) {
  .pass-code-container .login-2fa-div .pass-code-image {
    display: none;
  }

  .pass-code-container p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .pass-code-form-div {
    height: unset;
  }

  .react-code-input {
    justify-content: center;
    display: flex !important;
    flex-wrap: wrap;
  }

  .react-code-input input {
    font-size: calc(1.5rem * var(--font-size-scale)) !important;
    width: 1.75rem !important;
    height: 2.35rem !important;
  }
}
