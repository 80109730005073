.detail-container-print {
  height: 1650px;
  width: 1250px;
  padding: 12px 75px 0px 75px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.detail-container-print .payment-detail-inner-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px; /* 2rem */
  margin-bottom: 80px; /* 5rem */
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-header
  .print-img {
  width: 48px; /* 3rem */
  aspect-ratio: var(--logo-aspect-ratio);
  margin-right: 16px; /* 1rem */
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-header
  .company-title {
  font-size: 48px; /* 3rem */
  font-weight: bold;
  color: black;
}

.detail-container-print .payment-detail-inner-div .payment-detail-title {
  font-size: 28px; /* 1.75rem */
  margin-bottom: 64px; /* 4rem */
  color: #333333;
  display: flex;
  justify-content: start;
}

.detail-container-print .payment-detail-inner-div .receipt-container {
  margin-bottom: 80px; /* 5rem */
}

.detail-container-print .payment-detail-inner-div .receipt-title {
  font-size: 26.4px; /* 1.65rem */
  color: #444444;
  margin-bottom: 24px; /* 1.5rem */
  text-align: start !important;
}

.detail-container-print .payment-detail-inner-div .receipt-row {
  display: flex;
  width: 100% !important;
  font-size: 23.2px; /* 1.45rem */
  padding: 16px; /* 1rem */
  border-bottom: 1px solid #a9a9a9;
  text-align: start;
}

.detail-container-print .payment-detail-inner-div .receipt-row .key {
  font-weight: bold;
  width: 50%;
}

.detail-container-print .payment-detail-inner-div .receipt-row .value {
  overflow: unset !important;
  display: block !important;
  width: 50%;
}

.detail-container-print .payment-detail-inner-div .payment-detail-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: start;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-info
  .partial-border {
  margin-top: auto;
  margin-bottom: 24px; /* 1.5rem */
  height: 1px;
  width: 100%;
  border-top: 1px solid black;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-info
  .info-row {
  font-size: 19.2px; /* 1.2rem */
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-footer {
  margin-top: auto;
  width: 100%;
  color: #999999;
  font-size: 16px; /* 1rem */
  text-align: center;
  display: flex;
  flex-direction: column;
}

.detail-container-print
  .payment-detail-inner-div
  .payment-detail-company-footer
  .bottom-info-div {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.detail-container-print
  .payment-detail-company-footer
  .bottom-info-div
  .bottom-info {
  margin-right: 8px; /* 0.5rem */
}

.detail-container-print
  .payment-detail-company-footer
  .bottom-info-div
  .right-pipe {
  border-right: 1px solid #999999;
  margin-right: 16px; /* 1rem */
  padding-right: 16px; /* 1rem */
}

@media print {
  .detail-container-print .payment-detail-inner-div {
    padding: 80px; /* 5rem */
    display: flex;
    justify-content: center;
  }

  .detail-container-print
    .payment-detail-inner-div
    .payment-detail-company-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 80px; /* 5rem */
  }

  .detail-container-print
    .payment-detail-inner-div
    .payment-detail-company-header
    .print-img {
    width: 34.94px;
    height: 39px;
    margin-right: 16px; /* 1rem */
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
  }

  .detail-container-print
    .payment-detail-inner-div
    .payment-detail-company-header
    .company-title {
    font-size: 24px; /* 1.5rem */
  }

  .detail-container-print .payment-detail-inner-div .payment-detail-title {
    font-size: 20px; /* 1.25rem */
    margin-bottom: 16px; /* 1rem */
    font-weight: bold;
  }

  .detail-container-print .payment-detail-inner-div .receipt-row {
    width: 100%;
    font-size: 16px; /* 1rem */
  }

  .detail-container-print
    .payment-detail-inner-div
    .payment-detail-company-footer {
    margin-top: 80px; /* 5rem */
    font-size: 14.4px; /* 0.9rem */
    width: 100%;
    display: flex;
    font-style: italic;
    justify-content: center;
  }
}
