:root {
  --login-div-height: 44rem;
  --login-div-width: 500px;
  --login-div-padding: 3.5rem;
}

.login-container {
  min-height: 120vh;
  width: 100%;
  display: flex;
}

.login-2fa-div {
  margin: auto;
  margin-top: 10rem;
  padding: var(--login-div-padding);
  box-shadow: var(--div-shadow-border);
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  display: flex;
  flex-direction: column;
  width: var(--login-div-width);
  height: var(--login-div-height);
  background-color: var(--div-background);
  color: var(--inside-div-text-color);
  position: relative;
  animation: fade-in-up 0.6s;
}

.login-2fa-div .register-title {
  display: block;
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: 650;
  text-align: center;
  margin-bottom: 1.25rem;
}

.login-container .login-2fa-div .register-subtitle {
  margin-bottom: 1.1rem;
  display: flex;
  font-size: calc(1rem * var(--font-size-scale));
  font-weight: bolder;
  text-align: center;
  text-decoration: underline;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--inside-div-text-color);
}

.login-container .login-2fa-div .login-image {
  width: var(--logo-login-width);
  aspect-ratio: var(--logo-aspect-ratio);
  margin-left: auto;
  margin-right: auto;
  display: block;
  cursor: pointer;
}

.login-container .login-2fa-div .login-form-div {
  margin-top: 0.75rem;
}

.login-container .login-2fa-div .check-box-div {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

.login-container .login-2fa-div .check-box-div .forgot-password {
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
  color: var(--inside-div-text-color);
  font-size: calc(0.925rem * var(--font-size-scale));
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1200px) {
  .login-2fa-div {
    box-shadow: none !important;
    border: none !important;
    background: none !important;
    color: var(--outside-div-text-color);
    padding: 1rem;
    justify-content: space-between;
    width: 75%;
    height: unset;
    position: unset;
  }

  .login-container .login-2fa-div .register-subtitle {
    color: var(--outside-div-text-color);
    margin-bottom: 0.75rem;
  }

  .login-container .login-2fa-div .login-form-div {
  }

  .login-container .login-2fa-div .forgot-password {
    color: var(--outside-div-text-color) !important;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .login-2fa-div {
    width: 100%;
    margin-top: 6rem;
  }
}

@media only screen and (max-width: 400px) {
  .login-2fa-div {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 320px) {
  .login-2fa-div {
  }

  .login-2fa-div .register-title {
    margin-bottom: 0.75rem;
  }
}
