.company-daily-statistics-div .generate-pdf-buton {
  width: 100%;
}

.company-daily-statistics-div .download-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 2rem;
}

.company-daily-statistics-div .download-result .icon {
  width: 1.75rem;
  height: 1.75rem;
}

.company-daily-statistics-div .download-result .info {
  margin-top: 0.25rem;
  font-size: calc(0.875rem * var(--font-size-scale));
}

.company-daily-statistics-div .download-result .download-link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: var(--font-weight-3);
  color: var(--outside-highlight-color);
  font-size: calc(0.875rem * var(--font-size-scale));
}

.company-daily-statistics-div .download-result .bottom-info {
  margin-top: 0.25rem;
  font-size: calc(0.85rem * var(--font-size-scale));
  opacity: var(--opacity-2);
  font-weight: 100;
}

.company-daily-statistics-div .choose-start-date-check {
  width: fit-content;
  margin-bottom: 1rem;
}
