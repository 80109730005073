.forgot-password-container {
  height: var(--result-div-height);
}

@media only screen and (max-width: 1500px) {
}
@media only screen and (max-width: 1200px) {
  .forgot-password-container {
    height: unset;
  }
}

@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
