body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  background: var(--background);
  transition: opacity 0.4s linear;
  height: fit-content !important;
}

@media print {
  html,
  body,
  #root {
    background: none;
  }
}
