.navbar-black {
  background-color: var(--nav-bar-background-color);
  height: var(--nav-bar-height);
  width: 100%;
  z-index: 200;
  position: fixed;
  top: 0;
  transition: background-color 0.25s linear, box-shadow 0.25s linear,
    height 0.3s linear;
  padding-top: 0.75rem;
  display: flex;
  align-items: start;
}

.nav-bar-sticky {
  background-color: var(--nav-bar-sticky-background-color);
  box-shadow: var(--nav-bar-sticky-dropdown-shadow);
  height: calc(
    var(--nav-bar-height) - var(--nav-bar-height-sticky-subtraction)
  );
}

.nav-title {
  color: var(--nav-bar-text-color);
  text-decoration: none;
  display: var(--nav-bar-title-display);
  align-items: center;
}

.nav-text {
  padding: 0.75rem;
  color: var(--nav-bar-text-color);
  opacity: 0.55;
  transition: all 0.25s ease-in-out;
  text-decoration: none;
  font-size: calc(1rem * var(--font-size-scale));
}

.nav-text-picked {
  opacity: 1;
  font-weight: bold;
}

.nav-text-sticky {
  color: var(--nav-bar-sticky-color) !important;
}

.nav-text-sticky-signed {
  color: var(--nav-bar-sticky-signed) !important;
  opacity: 1 !important;
}

.dropdown-div-sticky #nav-dropdown-dark-example {
  color: var(--nav-bar-sticky-dropdown-link) !important;
}

.navbar-expand .navbar-nav {
  align-items: center;
}

.navbar-img {
  width: var(--logo-navbar-width);
  aspect-ratio: var(--logo-aspect-ratio);
  margin-right: 0.5rem;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.navbar-black .navbar-brand {
  color: var(--nav-bar-text-color);
  transition: color 0.25s linear;
  cursor: pointer;
  font-size: calc(1.25rem * var(--font-size-scale));
}

.navbar-black .nav-link {
  color: var(--nav-bar-text-color);
  opacity: 0.55;
  transition: color 0.25s linear, opacity 0.25s linear;
}

.navbar-black .nav-link.active,
.navbar-black .nav-link.show {
  color: var(--nav-bar-text-color);
}

.navbar-right-div {
  display: flex;
  align-items: center;
}

.signed-in-info-div {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: column;
  color: var(--nav-bar-signed);
  align-items: end;
  font-size: calc(1rem * var(--font-size-scale));
}

.signed-in-info-div .dropdown-div {
}

.dropdown-div #nav-dropdown-dark-example {
  transition: opacity 0.25s linear;
  padding: 0;
}

.signed-in-info-div .dropdown-div .dropdown-menu {
  background: var(--div-background) !important;
  border: var(--div-border);
}

.signed-in-info-div .dropdown-div a {
  padding: 0rem;
  display: flex;
  align-items: center;
  font-size: calc(1rem * var(--font-size-scale));
}

.signed-in-info-div .dropdown-div .item-dropdown {
  padding: 0.25rem 1rem;
  color: var(--inside-div-text-color);
}

.signed-in-info-div .dropdown-div .item-dropdown:hover {
  background-color: var(--selected-background-color);
  color: var(--selected-text-color);
}

.signed-in-info-div .dropdown-div .item-dropdown .item-icon {
  margin-right: 0.25rem;
}

@media only screen and (max-width: 1500px) {
  :root {
    --nav-bar-height-sticky-subtraction: 0.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  :root {
    --nav-bar-height: 4.25rem;
  }

  .navbar-black {
    overflow: hidden;
    max-width: 100%;
    z-index: 200;
    display: flex;
    align-items: center;
  }

  .nav-bar-sticky {
    background-color: var(--nav-bar-sticky-background-color);
    height: calc(
      var(--nav-bar-height) -
        var(--nav-bar-height-sticky-subtraction-responsive)
    );
  }

  .navbar-black .container {
    align-items: stretch !important;
  }

  .navbar-black .container .navbar-img {
  }

  .navbar-black .container .navbar-brand {
    margin-left: 0.5rem;
  }

  .side-bar {
    border-bottom: 0.5rem solid var(--outside-highlight-color) !important;
    border-top: none;
    height: fit-content !important;
    overflow: hidden;
  }

  .side-bar .side-bar-header {
    background-color: var(--nav-bar-side-bar-background);
    color: var(--nav-bar-side-bar-text-color);
    border: none !important;
    flex-direction: column;
    align-items: start;
  }

  .side-bar .inner-side-bar-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .side-bar .inner-side-bar-body {
    background-color: var(--nav-bar-side-bar-background);
    color: var(--nav-bar-side-bar-text-color);
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .side-bar .inner-side-bar-body .bottom-section {
    border-top: 1px solid var(--outside-div-text-color);
    display: flex;
    flex-direction: column;
  }

  .side-bar .inner-side-bar-body .bottom-section .theme-region {
    padding: 0.5rem 0rem;
    display: flex;
    align-items: center;
  }

  .side-bar .inner-side-bar-body .bottom-section .logout-item {
    padding: 0.5rem 0rem 0rem 0rem;
    display: flex;
    align-items: center;
  }

  .side-bar .inner-side-bar-body .bottom-section .item-icon {
    margin-right: 0.25rem;
  }

  .top-bar-toggle {
    color: var(--icon-color);
    font-size: 2rem;
  }

  .layout-buttons-div {
    margin: 2rem auto 0.5rem auto;
    width: 50%;
  }
  .signed-in-info-div {
    align-items: center;
  }
}

@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
