:root {
}

.asset-detail-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.asset-detail-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.asset-detail-body .asset-detail-div {
  display: flex;
  flex-direction: column;
}

.asset-detail-body .asset-detail-div .asset-detail-balance {
  width: 100%;
  height: 8rem;
  border-radius: var(--div-border-radius);
  color: var(--inside-div-text-color);
  border: var(--div-border);
  background-color: var(--div-background);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.asset-detail-body .asset-detail-div .asset-detail-balance .metadata-column {
  display: flex;
}

.asset-detail-body .asset-detail-div .asset-detail-balance .balance-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.asset-detail-body
  .asset-detail-div
  .asset-detail-balance
  .metadata-column
  .image {
  display: none;
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--inside-highlight-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.asset-detail-body
  .asset-detail-div
  .asset-detail-balance
  .metadata-column
  .text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  text-align: start;
}

.asset-detail-body
  .asset-detail-div
  .asset-detail-balance
  .metadata-column
  .text
  .title {
  font-weight: bold;
  font-size: calc(1.1rem * var(--font-size-scale));
}

.asset-detail-body
  .asset-detail-div
  .asset-detail-balance
  .metadata-column
  .text
  .abbreviation {
  font-size: 1rem;
}

.asset-detail-body .asset-detail-div .asset-detail-balance .buttons-column {
  display: flex;
}

.asset-detail-body .asset-detail-div .asset-detail-balance .left-button {
  margin-right: 0.5rem;
}

.asset-detail-body .asset-detail-div .time-length-div {
  display: flex;
  justify-content: space-between;
}

.asset-detail-body .asset-detail-div .time-length-div .filter-div {
  display: flex;
}

.asset-detail-body
  .asset-detail-div
  .time-length-div
  .filter-div
  .compare-option-div {
  /*
  width: fit-content;
  margin-right: 2rem;
  display: flex;
  align-items: center;*/
}

.asset-detail-body
  .asset-detail-div
  .time-length-div
  .filter-div
  .chart-length-option-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 15rem;
}

.asset-detail-body
  .asset-detail-div
  .time-length-div
  .filter-div
  .chart-length-option-div
  .option {
  cursor: pointer;
  opacity: 0.5;
  font-size: calc(0.9rem * var(--font-size-scale));
  transition: all 0.2s;
}

.asset-detail-body
  .asset-detail-div
  .time-length-div
  .filter-div
  .chart-length-option-div
  .option-picked {
  font-size: calc(1rem * var(--font-size-scale));
  opacity: 1;
}

.asset-detail-body .asset-detail-div .chart-div {
  min-height: 20rem;
  margin-bottom: 2rem;
}

.asset-detail-body .asset-detail-div .transactions-div {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.asset-detail-body .asset-detail-div .filter-search-div {
}

.asset-detail-body .asset-detail-div .transactions-div .card-portfolio {
  margin: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  height: 6rem;
  color: var(--inside-div-text-color);
  background: var(--div-background);
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  font-size: calc(0.8rem * var(--font-size-scale));
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .metadata-column {
  display: flex;
  width: 30%;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .metadata-column
  .image {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--inside-success-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .metadata-column
  .image
  .interior-letter {
  font-size: calc(0.95rem * var(--font-size-scale));
  font-weight: bold;
  color: var(--div-background);
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .metadata-column
  .text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: calc(100% - 4rem);
  overflow: hidden;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .metadata-column
  .text
  .title {
  font-weight: bold;
  font-size: calc(0.95rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .metadata-column
  .text
  .date {
  font-size: calc(0.825rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .operation-number-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 25%;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .status-column {
  text-align: start;
  font-weight: bold;
  width: 20%;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .description-column {
  text-align: start;
  width: 30%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .amount-column {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 20%;
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .amount-column
  .amount {
  font-weight: bold;
  font-size: calc(0.95rem * var(--font-size-scale));
  color: var(--inside-success-color);
}

.asset-detail-body
  .asset-detail-div
  .transactions-div
  .card-portfolio
  .amount-column
  .amount-danger {
  color: var(--inside-danger-color);
}

@media only screen and (max-width: 1500px) {
}
@media only screen and (max-width: 1400px) {
  .asset-detail-body .asset-detail-div .asset-detail-balance {
    height: 7rem;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .asset-detail-body .asset-detail-div .asset-detail-balance .buttons-column {
    flex-direction: column;
    align-items: end;
  }

  .asset-detail-body .asset-detail-div .asset-detail-balance .left-button {
    margin-right: 0rem;
    margin-bottom: 0.25rem;
  }
}

@media only screen and (max-width: 450px) {
  :root {
    --padding-cards: 0.75rem;
  }
}

@media only screen and (max-width: 320px) {
}
