.pay-me-body .fee-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--outside-div-text-color);
  text-align: start;
}

.pay-me-body .fee-div .fee-row {
  display: flex;
  justify-content: space-between;
  font-size: calc(0.85rem * var(--font-size-scale));
}

.pay-me-body .loading-quote {
  margin-top: 1rem;
}

.pay-me-body .fee-div .notice-row {
  margin-top: 0.5rem;
  font-size: calc(0.8rem * var(--font-size-scale));
  opacity: 0.9;
}
