.no-funds-div {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  color: var(--outside-div-text-color);
  align-items: center;
  width: var(--login-div-width);
  margin-left: auto;
  margin-right: auto;
}

.no-funds-div .no-funds-icon {
  font-size: calc(3rem * var(--font-size-scale));
}

.no-funds-div .title {
  color: var(--outside-div-text-color);
  text-align: start;
  font-size: calc(1.1rem * var(--font-size-scale)) !important;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.no-funds-div .sub-title {
  font-size: calc(1rem * var(--font-size-scale));
  font-weight: 100;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
}

.no-funds-div .buttons-div {
  width: 100%;
}
