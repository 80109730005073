:root {
  --result-div-height: calc(var(--login-div-height) / 1.1);
}

.result-container {
  min-height: 95vh;
  width: 100%;
  display: flex;
  animation: fade-in-up 0.6s;
}

.result-container .login-2fa-div {
  height: var(--result-div-height);
}

.result-container .login-2fa-div .register-title {
  display: block;
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: 650;
  text-align: center;
  margin-bottom: 24px;
  color: var(--inside-div-text-color);
}

.result-container .login-2fa-div .result-image {
  height: 73px;
  width: 73px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.result-buttons-div .result-button {
  margin-top: 24px;
  width: 100%;
  height: 50px;
}

.result-form-div {
  color: var(--inside-div-text-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.result-form-div .icon {
  font-size: 5rem;
  color: var(--inside-highlight-color);
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.result-form-div h3 {
  font-weight: bold;
  margin-bottom: 16px;
}

.result-container .continue-button {
  margin-top: 24px;
  width: 100%;
  height: 3.5rem;
}
@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1200px) {
  .result-container .login-2fa-div {
    height: unset;
    width: 50%;
  }
  .result-container .login-2fa-div .register-title {
    color: var(--outside-div-text-color);
  }

  .result-form-div {
    color: var(--outside-div-text-color);
  }

  .result-form-div .icon {
    color: var(--outside-highlight-color);
  }
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
  .result-container .login-2fa-div {
    height: unset;
  }

  .result-container .login-2fa-div .register-title {
    color: var(--outside-div-text-color);
  }

  .result-form-div {
    color: var(--outside-div-text-color);
  }

  .result-form-div .icon {
    color: var(--outside-highlight-color);
  }
}
@media only screen and (max-width: 320px) {
}
