.go-back-button {
  color: var(--outside-highlight-color);
  border: var(--button-button-border);
  background: none;
  width: fit-content;
  height: fit-content;
  font-size: calc(1rem * var(--font-size-scale));
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.go-back-button:hover {
  color: var(--outside-highlight-color);
  border: var(--button-button-border);
  background: none;
}

.go-back-button:active {
  color: var(--outside-highlight-color) !important;
  border: var(--button-button-border) !important;
  background: none !important;
}

.go-back-button .arrow {
  margin-right: 0.2rem;
}

.layout {
  padding: 0rem !important;
  height: 100%;
}

.layout-sticky {
  padding: 0rem !important;
  height: 100%;
  color: var(--nav-bar-sticky-color) !important;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  :root {
    --back-button-height: 2rem;
  }

  .go-back-button {
    padding-left: 1.25rem;
    padding-top: 0;
    height: var(--back-button-height);
  }

  .go-back-button .arrow {
    display: block;
  }
}
