.result-component-container {
  margin: auto;
  width: var(--login-div-width);
  color: var(--outside-div-text-color);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  text-align: center;
  animation: fade-in-up 0.6s;
}

.result-component-container .icon {
  font-size: calc(3.5rem * var(--font-size-scale));
  color: var(--outside-highlight-color);
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.result-component-container .result-title {
  text-align: center;
  font-weight: bold;
  font-size: calc(1.25rem * var(--font-size-scale));
}

.result-component-container .description {
  font-size: calc(0.85rem * var(--font-size-scale));
  margin: 1rem 0rem 1rem 0rem;
}

.result-component-container .continue-button {
  margin-top: 1.75rem;
  width: 100%;
  height: 3.5rem;
}

.result-component-container .back-button {
  margin-top: 1rem;
  width: 100%;
  height: 3.5rem;
}

.result-component-container .receipt-download-print-div .receipt-container {
  font-size: calc(0.85rem * var(--font-size-scale));
}

.result-component-container
  .receipt-download-print-div
  .receipt-container
  .receipt-row
  .key {
  text-align: start;
}

.result-component-container
  .receipt-download-print-div
  .receipt-container
  .receipt-row
  .value {
  text-align: end;
}

.result-component-container .receipt-download-print-div {
  margin: 2rem 0rem;
}

.result-component-container .receipt-download-print-div .icons-row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(2rem * var(--font-size-scale));
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  height: 2rem;
}

.result-component-container .receipt-download-print-div .icons-row .left-icon {
  cursor: pointer;
  margin-right: 2rem;
}

.result-component-container .receipt-download-print-div .icons-row .right-icon {
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .result-component-container {
    width: 100%;
    padding: 1.5rem;
  }
}
