.primary-modal .modal-content .verifications-div {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  margin-top: 0.5rem;
}

.primary-modal .modal-content .verifications-div .verification-row {
  text-decoration: underline;
  list-style: disc outside none;
  line-height: 1.75rem;
  cursor: pointer;
  font-weight: 600;
}

.primary-modal .two-verification-required {
  display: flex;
  justify-content: space-between;
}

.go-verify-button {
  margin-left: 0.25rem;
  height: 2.5rem;
}

.primary-modal .two-button-div {
  display: flex;
}
