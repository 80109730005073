.notice-div {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  color: var(--outside-div-text-color);
  border: 1px var(--outside-div-text-color) solid;
  border-radius: var(--div-border-radius);
  width: 80%;
  margin: auto;
}

.notice-div .notice-title {
  margin-bottom: 0.25rem;
  font-weight: var(--font-weight-3);
  font-size: calc(0.85rem * var(--font-size-scale));
}

.notice-div .notice-message {
  font-size: calc(0.85rem * var(--font-size-scale));
}
