:root {
}

.add-plugin-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.add-plugin-container .add-plugin-body {
  width: 100%;
  display: flex;
}

.add-plugin-container .add-plugin-body .vendor-form-div {
  width: 75%;
  margin: auto;
}

.add-plugin-container .add-plugin-body .vendor-form-div .main-title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
  margin-bottom: var(--title-margin-bottom);
  text-align: start;
}

.add-plugin-container .add-plugin-body .vendor-form-div .error-message {
  margin-top: 1rem;
  color: var(--inside-danger-color);
  text-align: center;
}

.add-plugin-container .add-plugin-body .loader {
  margin-top: 6rem;
}

.add-plugin-container .form-check {
  display: flex;
}

.add-plugin-container .form-check label {
  margin-left: 1rem;
  color: var(--outside-div-text-color);
}

.add-plugin-container .div-parent-flex-row {
  align-items: start;
}

.add-plugin-container .html-schema-form-div {
  display: flex;
  justify-content: space-between;
}

.add-plugin-container .html-schema-form-div .form-control {
  width: calc(50% - 0.5rem - 4rem);
}

.add-plugin-container .html-schema-form-div .outside-primary-button {
  width: 4rem;
}

.add-plugin-container .html-schema-form-div .html-schema-row-icon {
  cursor: pointer;
  width: 4rem;
  font-size: calc(1.5rem * var(--font-size-scale));
}

.add-plugin-container .invoice-after-payment-check {
  margin-top: 0.5rem;
}

@media only screen and (max-width: 1500px) {
  .add-plugin-container .add-plugin-body .side-container {
    width: 20%;
  }

  .add-plugin-container .add-plugin-body .middle-container {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .add-plugin-container .add-plugin-body .middle-container {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  .add-plugin-container {
    padding: 0rem 0.75rem;
  }

  .add-plugin-container .add-plugin-body .middle-container {
    width: 100%;
  }

  .add-plugin-container .add-plugin-body .vendor-form-div {
    width: 100%;
  }
}
