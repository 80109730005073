:root {
  --image-width: 4rem;
}

.home-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.home-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.hub-container {
  display: flex;
  flex-direction: column;
  animation: fade-in-up 0.6s;
}

.hub-container .hub-row {
  color: var(--outside-div-text-color);
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
}

.hub-container .hub-row .title {
  text-align: start;
  font-size: calc(1.075rem * var(--font-size-scale));
  font-weight: bold;
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
}

.hub-container .hub-row .hub-buttons-div {
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  /*margin-top: calc(var(--total-balance-div) + var(--filter-height) + 1rem);*/
}

.hub-container .hub-row .hub-buttons-div .hub-card {
  width: calc((100% / 3) - 0.5rem);
  margin: 0.25rem;
  aspect-ratio: 1.25;
  border-radius: var(--div-border-radius);
  color: var(--operation-card-text-color);
  display: flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  box-shadow: var(--div-shadow-border);
}

.hub-container .hub-row .hub-buttons-div .hub-card:hover {
  transform: scale(1.0075);
  border: 2px solid var(--outside-highlight-color);
  box-shadow: var(--div-shadow-border-hover);
}

.hub-container .hub-row .hub-buttons-div .hub-card .text {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  justify-content: space-evenly;
  align-items: start;
  text-align: start;
  z-index: 10;
  filter: 0;
  width: 100%;
  transition: filter 0.5s;
}

.hub-container .hub-row .hub-buttons-div .hub-card:hover .text {
}

.hub-container .hub-row .hub-buttons-div .hub-card .text .title {
  font-size: calc(1.1rem * var(--font-size-scale));
  font-weight: bold;
  margin: unset;
}

.hub-container .hub-row .hub-buttons-div .hub-card .text .description {
  font-size: calc(0.85rem * var(--font-size-scale));
  font-style: italic;
  height: 3rem;
  opacity: var(--opacity-2);
}

.hub-container .hub-row .hub-buttons-div .hub-card .icon {
  font-size: calc(1.45rem * var(--font-size-scale));
  color: var(--operation-card-text-color);
  margin-right: 0.5rem;
}

.hub-container .hub-row .hub-buttons-div .hub-card .image {
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  filter: 0;
  transition: filter 0.5s;
}

.hub-container .hub-row .hub-buttons-div .hub-card:hover .image {
}

.hub-container .hub-row .hub-buttons-div .hub-card .image-src-1 {
  background: var(--operation-cards-background);
}

.hub-container .hub-row .hub-buttons-div .hub-card .image-src-2 {
  background: var(--operation-cards-background);
  transform: scaleX(-1);
}

@media only screen and (max-width: 1500px) {
  :root {
    --image-width: 3.75rem;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card .text .title {
    font-size: calc(1.075rem * var(--font-size-scale));
  }

  .hub-container .hub-row .hub-buttons-div .hub-card .icon {
    font-size: calc(1.45rem * var(--font-size-scale));
  }
}

@media only screen and (max-width: 1400px) {
  .hub-container .hub-row .hub-buttons-div .hub-card .text {
    padding: 1rem;
  }
}

@media only screen and (max-width: 1300px) {
  :root {
    --image-width: 3.5rem;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card {
  }
}

@media only screen and (max-width: 1200px) {
  .home-container .home-body .middle-container {
    width: 100%;
  }

  .home-container .home-body .side-container {
    display: none;
  }

  .hub-container .hub-row .hub-buttons-div {
    justify-content: unset;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card {
    width: calc((100% / 3) - 0.5rem);
    margin: 0.25rem;
    aspect-ratio: 1.25;
  }
}

@media only screen and (max-width: 900px) {
  .hub-container .hub-row .hub-buttons-div .hub-card {
    width: calc(50% - 0.5rem);
    margin: 0.25rem;
    aspect-ratio: 1.5;
  }
}

@media only screen and (max-width: 600px) {
  .hub-container .hub-row {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .hub-container .hub-row .hub-buttons-div {
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem;
  }

  .hub-container .hub-row .title {
    margin-left: 0.25rem;
    margin-bottom: 0.5rem;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card {
    width: calc(50% - 0.5rem);
    margin: 0.25rem;
    aspect-ratio: 1.15;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card .icon {
    margin-right: 0.25rem;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card .text {
    padding: 0.65rem;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card .text .title {
    font-size: calc(1.1rem * var(--font-size-scale));
    display: flex;
    align-items: center;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card .text .description {
    height: 4rem;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card:hover {
    box-shadow: unset;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card:hover .text {
    filter: unset;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card:hover .icon {
    opacity: unset;
  }

  .hub-container .hub-row .hub-buttons-div .hub-card:hover .image {
    filter: unset;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
  .hub-container .hub-row .hub-buttons-div .hub-card {
    width: calc(100% - 0.5rem);
    margin: 0.25rem;
    aspect-ratio: 1.75;
  }
}
