.receipt-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.receipt-container .receipt-title {
  font-size: calc(1rem * var(--font-size-scale));
  margin-bottom: 0.5rem;
}

.receipt-container .receipt-row {
  font-size: calc(0.9rem * var(--font-size-scale));
  display: flex;
  margin-bottom: 0.25rem;
}

.receipt-container .receipt-row .key {
  font-weight: bold;
  width: 10rem;
  margin-right: 2.5rem;
}

.receipt-container .receipt-row .value {
  width: calc(100% - 10rem);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.3rem;
}

.receipt-container .receipt-row-centered {
  justify-content: space-between;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.receipt-container .receipt-row-centered .value {
  text-align: right;
}

.receipt-container .status-row {
  margin-top: 0.5rem;
}

.receipt-container .status-row .status-value {
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  width: fit-content;
  text-transform: capitalize;
}

.payment-receipt-footer a {
  font-weight: bold;
  color: var(--outside-highlight-color);
  text-decoration: none;
}

@media only screen and (max-width: 450px) {
  .receipt-container .receipt-row {
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }

  .receipt-container .receipt-row .value {
    text-align: end;
  }
}
