.settings-container {
  width: 100%;
  min-height: 135vh;
  margin-bottom: 15rem;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.settings-body {
  height: 100%;
  width: 100%;
  display: flex;
  text-align: start;
  color: var(--outside-div-text-color);
}

.settings-container .settings-body .middle-container {
  height: 100%;
}

.settings-body .title {
  margin-bottom: var(--title-margin-bottom);
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
}

.settings-body .sub-title {
  font-weight: 500;
  margin-bottom: 0.75rem;
  text-decoration: underline;
}

.settings-body .description {
  font-style: italic;
  margin-bottom: 1rem;
}

.settings-body .account-info {
}

.settings-body .account-info .bottom-section-div {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-body .auth-div {
  margin-bottom: 1rem;
}

.settings-body .auth-div .toggle-div {
  display: flex;
  justify-content: space-between;
}

.settings-body .verification-div {
  margin-bottom: 1rem;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.settings-body .verification-div .verification-info-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--small-button-height);
}

.settings-body .verification-div .verification-info-div .current-verified {
  display: flex;
  align-items: center;
}

.settings-body
  .verification-div
  .verification-info-div
  .current-verified
  .verified-icon {
  color: var(--outside-success-color);
  margin-right: 0.25rem;
}

.settings-body
  .verification-div
  .verification-info-div
  .current-verified
  .not-verified-icon {
  color: var(--outside-danger-color);
  margin-right: 0.25rem;
}

.settings-body .verification-div .toggle-button-div {
  display: flex;
  align-items: center;
}

.settings-body .disable-div {
  margin-top: 1rem;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.settings-body .disable-buttons-div {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.settings-body .disable-buttons-div .top-button {
  margin-bottom: 0.5rem;
}

.settings-modal {
}

.settings-modal .modal-content {
  background-color: var(--modal-background);
  color: var(--modal-text-color);
  border-radius: var(--modal-border-radius);
}

.settings-modal .modal-content .btn-close {
  background-image: var(--modal-icon-close);
}

.settings-modal .modal-content .btn-close:focus {
  box-shadow: none;
  opacity: none;
}

.settings-modal.settings-modal .modal-header {
  border: none;
}

.settings-modal.settings-modal .modal-header .modal-title {
  display: flex;
  align-items: center;
  font-size: calc(
    var(--page-title-font-size) * var(--font-size-scale)
  ) !important;
}

.settings-modal .modal-body {
  display: flex;
  flex-direction: column;
  border: none;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.settings-modal .modal-footer {
  border: none;
}

.settings-modal .qrcode {
  margin: 0.75rem auto 0.75rem auto;
  width: 15rem;
  height: 15rem;
}

.settings-modal .key-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings-modal .key-div .key {
  display: flex;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

.settings-modal .key-div .key .copy-key-icon {
  margin-left: 0.5rem;
  font-size: calc(1.5rem * var(--font-size-scale));
}

.settings-modal .key-div .key .copy-success-icon {
  margin-left: 0.5rem;
  font-size: calc(1.5rem * var(--font-size-scale));
  color: var(--inside-success-color);
  animation: animate-fade 0.25s;
}

.settings-modal .warning-icon {
  font-size: calc(1.5rem * var(--font-size-scale));
  margin-right: 0.25rem;
}

.settings-modal b {
  text-decoration: underline;
  cursor: pointer;
}

.settings-container .settings-body .loader-div {
  display: flex;
  height: 35rem;
}

.settings-container .settings-body .accordion-settings {
  color: var(--outside-div-text-color);
  animation: fade-in-up 0.6s;
}

.settings-container .settings-body .accordion-settings .accordion-item {
  border: none;
  background: none;
  color: var(--outside-div-text-color);
}

.settings-container
  .settings-body
  .accordion-settings
  .accordion-item
  .accordion-header {
  background: none;
}

.settings-container
  .settings-body
  .accordion-settings
  .accordion-item
  .accordion-header
  .accordion-button {
  color: var(--outside-div-text-color);
  padding: 1rem 0rem;
  font-size: calc(1rem * var(--font-size-scale));
  background: none;
  font-weight: var(--font-weight-3);
}

.settings-container
  .settings-body
  .accordion-settings
  .accordion-item
  .accordion-body {
  padding: 0.5em 0rem 1rem 0rem;
}

.settings-container
  .settings-body
  .accordion-settings
  .accordion-item
  .accordion-header
  .accordion-button:focus {
  box-shadow: none;
}

.settings-container
  .settings-body
  .accordion-settings
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.settings-container
  .settings-body
  .accordion-settings
  .accordion-item
  .accordion-header
  .accordion-button::after {
  background-image: var(--accordion-icon);
}

.settings-container
  .settings-body
  .accordion-settings
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed)::after {
  background-image: var(--accordion-icon);
}

.settings-container .settings-body .loader-small-outside {
  margin-right: 0.75rem;
}

.settings-container .settings-body .scroll-navigation-div {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 14rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;
  color: var(--outside-div-text-color);
  border: var(--outside-div-text-color) 1px solid;
  border-radius: var(--div-border-radius);
}

.settings-container
  .settings-body
  .scroll-navigation-div
  .scroll-navigation-item {
  cursor: pointer;
  height: 2.25rem;
  font-size: 0.9rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  transition: 0.2s all ease;
}

.settings-container
  .settings-body
  .scroll-navigation-div
  .scroll-navigation-item:hover {
}

.settings-container
  .settings-body
  .scroll-navigation-div
  .scroll-navigation-item-picked {
  font-weight: var(--font-weight-3);
}

@media only screen and (max-width: 1500px) {
  .settings-modal .qrcode {
    margin: 0.75rem auto 0.75rem auto;
    width: 14rem;
    height: 14rem;
  }
}

@media only screen and (max-width: 1300px) {
  .settings-modal .qrcode {
    margin: 0.75rem auto 0.75rem auto;
    width: 13rem;
    height: 13rem;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  .settings-container {
  }

  .settings-body {
  }

  .settings-container .settings-body .middle-container {
    width: 100%;
  }

  .settings-modal .modal-header {
    padding: 0.75rem;
  }

  .settings-modal .modal-body {
    padding: 0.75rem;
  }

  .settings-modal .modal-footer {
    border: none;
  }

  .settings-modal .key-div .key {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
