.conversion-component-container {
  display: flex;
  flex-direction: column;
  margin: 0rem 1rem;
  margin-top: calc(var(--total-balance-icons-height));
  padding: 1rem;
  animation: fade-in-up 0.6s;
}

.for-page {
  margin-top: 1rem;
}

.conversion-component-container #conversion-type-switch {
}

.conversion-component-container #conversion-type-switch:checked {
  background-color: var(--inside-highlight-color);
  border-color: var(--inside-highlight-color);
}

.conversion-component-container #conversion-type-switch:focus {
  box-shadow: none;
  border-color: var(--inside-highlight-color);
}

.conversion-component-container .conversion-component-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.conversion-component-container
  .conversion-component-title-div
  .conversion-component-title {
  text-align: start;
  font-size: calc(1.05rem * var(--font-size-scale));
  /*font-weight: bold;*/
}

.conversion-component-container .conversion-component-sub-title-div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 1rem;
}

.conversion-component-container
  .conversion-component-sub-title-div
  .input-field {
  height: fit-content;
  width: 8rem;
}

.conversion-component-container
  .conversion-component-sub-title-div
  .input-field-page {
  flex-grow: 1;
}

.conversion-component-container
  .conversion-component-sub-title-div
  .conversion-component-sub-title {
  text-align: right;
  font-size: calc(0.9rem * var(--font-size-scale));
  cursor: pointer;
}

.conversion-component-container .conversion-component-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 3rem;
}

.conversion-component-container .currency-amount-container {
  display: flex;
  flex-direction: column;
  width: calc(100% / 2 - 2.15rem);
}
.conversion-component-container
  .currency-amount-container
  .conversion-sub-title {
  text-align: start;
  font-size: 1rem;
  margin-bottom: 0.3rem;
  color: var(--inside-div-text-color);
}

.conversion-component-container
  .currency-amount-container
  .currency-amount-div {
  width: 100%;
}

.conversion-component-container .conversion-switch {
  font-size: 2rem;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 0.5rem;
}

.conversion-rate-detail-container {
  display: flex;
  flex-direction: column;
  margin: 0rem 1rem;
  margin-top: 1rem;
  padding: 1rem;
  animation: fade-in-up 0.6s;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
}

.conversion-rate-detail-div {
  text-align: start;
}

.conversion-rate-detail-div .conversion-rate-detail-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.conversion-rate-detail-div
  .conversion-rate-detail-title-div
  .conversion-rate-detail-title {
  font-size: calc(0.95rem * var(--font-size-scale)) !important;
}

.conversion-rate-detail-div .conversion-rate-detail-div-row {
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.25rem;
}

.conversion-rate-detail-div .conversion-rate-detail-div-row .row-label {
  margin-right: 1rem;
  opacity: var(--opacity-2);
}

.conversion-rate-detail-div .conversion-rate-detail-div-row .row-value {
  text-align: end;
}

.conversion-rate-detail-div
  .conversion-rate-detail-div-row
  .loading-conversion-lock {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  color: var(--inside-text-color);
}

@media only screen and (max-width: 1500px) {
  .conversion-component-container {
    padding: 0.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .conversion-rate-detail-container {
    padding: 0.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .conversion-component-container .conversion-component-div {
    /*
    display: flex;
    flex-direction: column;
    align-items: center;*/
  }

  .conversion-component-container .currency-amount-container {
    /* width: 100%;*/
  }

  .conversion-component-container .conversion-switch {
    /*margin-top: 1rem;
    margin-bottom: 1rem;
    rotate: 90deg;*/
    font-size: 1.25rem;
  }

  .conversion-component-container .conversion-component-sub-title-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    height: fit-content;
  }

  .conversion-component-container
    .conversion-component-sub-title-div
    .input-field {
    width: 100%;
    margin-bottom: 1rem;
  }

  .conversion-component-container
    .conversion-component-sub-title-div
    .conversion-component-sub-title {
    margin-left: auto;
  }
}

@media only screen and (max-width: 1400px) {
  .conversion-component-container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .conversion-rate-detail-container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 1300px) {
  .conversion-component-container {
    padding: 0.5rem;
  }

  .conversion-rate-detail-container {
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
  .for-page {
    margin: 1rem 0rem 0rem 0rem;
  }

  .conversion-rate-detail-container {
    margin: 1rem 0rem 0rem 0rem;
  }
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
