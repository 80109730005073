:root {
}

.portfolio-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.portfolio-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.portfolio-body .tab-content {
  animation: fade-in-up 0.6s;
}

.portfolio-body .time-length-div {
  display: flex;
  padding: 0rem 0.5rem;
  margin-top: var(--margin-content-under-tab);
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.portfolio-body .time-length-div .filter-div {
  display: flex;
}

.portfolio-body .time-length-div .filter-div .compare-option-div {
  /*
  width: fit-content;
  margin-right: 2rem;
  display: flex;
  align-items: center;*/
}

.portfolio-body .time-length-div .filter-div .chart-length-option-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 15rem;
  color: var(--outside-div-text-color);
}

.portfolio-body .time-length-div .filter-div .chart-length-option-div .option {
  cursor: pointer;
  opacity: 0.5;
  font-size: calc(0.9rem * var(--font-size-scale));
  transition: all 0.2s;
}

.portfolio-body
  .time-length-div
  .filter-div
  .chart-length-option-div
  .option-picked {
  font-size: calc(1rem * var(--font-size-scale));
  opacity: 1;
}

.portfolio-body .time-length-div .currency-filter-div {
  margin-left: 1rem;
}

.portfolio-body .div-cards {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.portfolio-body .div-cards .card-asset {
  margin: 0.5rem;
  padding: 0.75rem 1rem;
  height: 6rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.15s ease-in-out;
}

.portfolio-body .div-cards .card-asset .metadata-column {
  display: flex;
}

.portfolio-body .div-cards .card-asset .metadata-column .image {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--inside-highlight-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.portfolio-body .div-cards .card-asset .metadata-column .text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  text-align: start;
}

.portfolio-body .div-cards .card-asset .metadata-column .text .title {
  font-weight: var(--font-weight-3);
  font-size: calc(1.1rem * var(--font-size-scale));
}

.portfolio-body .div-cards .card-asset .metadata-column .text .abbreviation {
  font-size: calc(1rem * var(--font-size-scale));
  opacity: var(--opacity-2);
}

.portfolio-body .div-cards .card-asset .chart-column {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-body .div-cards .card-asset .favorite-column {
  width: 1.5rem;
}

.portfolio-body .div-cards .card-asset .favorite-column .favorite-icon {
  font-size: calc(1.25rem * var(--font-size-scale));
}

.portfolio-body .div-cards .card-asset .balance-column {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 10%;
  min-width: 5rem;
}

.portfolio-body .div-cards .card-asset .balance-column .value {
  font-weight: bold;
  font-size: calc(1rem * var(--font-size-scale));
}

.portfolio-body .div-cards .card-asset .balance-column .percentage {
  font-size: calc(0.75rem * var(--font-size-scale));
  opacity: var(--opacity-2);
}

.portfolio-body .skeleton-card-seperator {
  height: var(--filter-height);
}

.portfolio-body .card-asset .card-body-skeleton {
  height: 4rem;
  width: 100%;
  background-color: var(--skeleton-background-color);
}

.portfolio-body .div-cards .card-asset .metadata-column-skeleton {
  width: 30%;
  display: flex;
  align-items: center;
}

.portfolio-body
  .div-cards
  .card-asset
  .metadata-column-skeleton
  .image-skeleton {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--skeleton-background-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.portfolio-body
  .div-cards
  .card-asset
  .metadata-column-skeleton
  .text-skeleton {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.div-cards
  .card-asset
  .metadata-column-skeleton
  .text-skeleton
  .title-skeleton {
  background-color: var(--skeleton-background-color);
  height: 1rem;
  width: 100%;
  border-radius: var(--div-border-radius);
}

.div-cards
  .card-asset
  .metadata-column-skeleton
  .text-skeleton
  .abbreviation-skeleton {
  background-color: var(--skeleton-background-color);
  margin-top: 0.5rem;
  height: 0.7rem;
  width: 80%;
  border-radius: var(--div-border-radius);
}

.portfolio-body .div-cards .card-asset .balance-column-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 30%;
}

.portfolio-body
  .div-cards
  .card-asset
  .balance-column-skeleton
  .value-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 1rem;
  width: 75%;
}

.portfolio-body
  .div-cards
  .card-asset
  .balance-column-skeleton
  .percentage-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 0.9rem;
  width: 50%;
  margin-top: 0.5rem;
}

.portfolio-body .div-cards .card-asset .graph-column-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 100%;
  width: 20%;
}

.portfolio-body .transaction-history-div {
}

.portfolio-body .transaction-history-div .div-transaction-cards {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio {
  margin: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  font-size: calc(0.8rem * var(--font-size-scale));
}

.portfolio-body .transaction-history-div .div-transaction-cards .selected-card {
  border: none;
  color: var(--selected-text-color) !important;
  background: var(--selected-background-color);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column {
  display: flex;
  width: 30%;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column
  .image {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--inside-success-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column
  .image-danger {
  background-color: var(--inside-danger-color);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column
  .image
  .interior-letter {
  font-size: calc(0.95rem * var(--font-size-scale));
  font-weight: bold;
  color: var(--div-background);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column
  .text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column
  .text
  .title {
  font-weight: bold;
  font-size: calc(0.95rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column
  .text
  .date {
  font-size: calc(0.825rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: var(--opacity-2);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .from-column {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .operation-number-column {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 22%;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .operation-number-column
  .from-to {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: start;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .status-column {
  text-align: start;
  font-weight: bold;
  width: 20%;
  opacity: var(--opacity-2);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .description-column {
  text-align: start;
  width: 30%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  opacity: var(--opacity-2);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .amount-column {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 20%;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .amount-column
  .amount {
  font-weight: bold;
  font-size: calc(0.95rem * var(--font-size-scale));
  color: var(--inside-success-color);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .amount-column
  .amount-danger {
  color: var(--inside-danger-color);
}

.portfolio-body .accordion-transaction-history {
}

.portfolio-body .accordion-transaction-history .accordion-item {
  color: var(--inside-div-text-color);
  border: none;
  background: none;
}

.portfolio-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header {
  background: none;
}

.portfolio-body .accordion-transaction-history .accordion-item .accordion-body {
  padding: 0.5em 0rem 1rem 0rem;
}

.portfolio-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button {
  background: none;
  color: var(--outside-highlight-color);
  font-weight: bold;
}

.portfolio-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button:focus {
  box-shadow: none;
}

.portfolio-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.portfolio-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button::after {
  background-image: var(--accordion-icon);
}

.portfolio-body
  .accordion-transaction-history
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed)::after {
  background-image: var(--accordion-icon);
}

.portfolio-body .skeleton-card-seperator {
  height: var(--filter-height);
}

.portfolio-body .card-portfolio .card-body-skeleton {
  height: 4rem;
  width: 100%;
  background-color: var(--skeleton-background-color);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column-skeleton {
  width: 25%;
  display: flex;
  align-items: center;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column-skeleton
  .image-skeleton {
  width: var(--image-width);
  aspect-ratio: 0.8888;
  background-color: var(--skeleton-background-color);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column-skeleton
  .text-skeleton {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .metadata-column-skeleton
  .text-skeleton
  .title-skeleton {
  background-color: var(--skeleton-background-color);
  height: 1rem;
  width: 100%;
  border-radius: var(--div-border-radius);
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .balance-column-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 30%;
}

.portfolio-body
  .transaction-history-div
  .div-transaction-cards
  .card-portfolio
  .balance-column-skeleton
  .value-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 1rem;
  width: 50%;
}

.transaction-history-detail .receipt-row .key {
}

.portfolio-body .loader-favorite {
  font-size: calc(1rem * var(--font-size-scale));
  margin-top: auto;
  margin-bottom: auto;
  width: 1.5rem !important;
  height: 1.5rem !important;
}

@media only screen and (max-width: 1500px) {
  .portfolio-body .side-container {
    width: 20%;
  }

  .portfolio-body .middle-container {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .portfolio-body .middle-container {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  :root {
    --padding-cards: 0.75rem;
  }

  .portfolio-container {
  }

  .portfolio-container .portfolio-body .middle-container {
    width: 100%;
  }

  .portfolio-body .div-cards {
    max-height: unset;
  }

  .portfolio-body .div-cards .card-asset {
    width: 96% !important;
    margin: 0.4em 2% 0.4rem 2%;
    transition: none;
  }

  .portfolio-body .div-cards .card-asset .content {
    padding: 0.5rem;
  }

  .portfolio-body .div-cards .card-asset .chart-column {
    width: 30%;
  }

  .portfolio-body .skeleton-card-seperator {
    display: none;
  }

  .portfolio-body .div-cards .card-asset .metadata-column-skeleton {
    width: 43%;
  }

  .portfolio-body .div-cards .card-asset .graph-column-skeleton {
    width: 25%;
  }

  .portfolio-body .div-cards .card-asset .chart-column {
    display: none;
  }

  .portfolio-body .div-cards .card-asset .balance-column-skeleton {
    width: 22%;
  }

  .portfolio-body
    .transaction-history-div
    .div-transaction-cards
    .card-portfolio
    .metadata-column {
    width: 60%;
  }

  .portfolio-body
    .transaction-history-div
    .div-transaction-cards
    .card-portfolio
    .operation-number-column {
    display: none;
  }

  .portfolio-body
    .transaction-history-div
    .div-transaction-cards
    .card-portfolio
    .amount-column {
    width: 30%;
  }

  .portfolio-body .time-length-div .filter-div .compare-option-div {
  }
}
