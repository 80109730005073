.deposit-body .deposit-static-div {
  display: flex;
  flex-direction: column;
  padding: 0rem 0.25rem;
  color: var(--outside-div-text-color);
}

.deposit-body .deposit-static-div .bank-info-message {
  font-size: calc(0.9rem * var(--font-size-scale));
  text-align: start;
  margin-bottom: 0.5rem;
}

.deposit-body .deposit-static-div .bank-info-subtitle {
  font-weight: var(--font-weight-3);
  font-size: calc(0.9rem * var(--font-size-scale));
  text-align: start;
  margin-bottom: 0.5rem;
}

.deposit-body .deposit-static-div .bank-info-div {
  display: flex;
  flex-direction: column;
}

.deposit-body .deposit-static-div .bank-info-div .info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  font-size: calc(0.825rem * var(--font-size-scale));
}

.deposit-body .deposit-static-div .bank-info-div .info-row .value {
  opacity: var(--opacity-2);
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
