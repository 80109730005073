:root {
  --small-form-width: 600px;
}

.id-verification-container {
  width: 100%;
  display: flex;
}

.id-verification-container .id-verification-title {
  display: block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  color: var(--outside-div-text-color);
  font-weight: bold;
  text-align: start;
  width: var(--small-form-width);
}

.id-verification-container .vouched {
  background: none !important;
  color: var(--outside-div-text-color);
}

.id-verification-container .vouched .flex-center {
  justify-content: start;
}

.id-verification-container .id-verification-form-div {
  width: var(--small-form-width);
  margin: auto;
  animation: fade-in-up 0.6s;
}

.id-verification-container .id-verification-form-div .message {
  font-size: calc(0.9rem * var(--font-size-scale));
  font-style: italic;
  color: var(--outside-div-text-color);
}

.id-verification-container .id-verification-version {
  margin-top: 1.5rem;
}

.loading-vouched {
  height: 20rem;
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 1500px) {
}
@media only screen and (max-width: 1200px) {
  :root {
    --small-form-width: 100%;
  }

  .id-verification-container .middle-container {
    width: 100%;
  }
  .id-verification-container .side-container {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
  .id-verification-container {
  }

  .id-verification-container .middle-container {
    width: 100%;
  }
  .id-verification-container .id-verification-title {
    width: var(--small-form-width);
  }

  .id-verification-container .id-verification-form-div {
    width: var(--small-form-width);
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
