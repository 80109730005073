:root {
  --close-icon-size: 1.5rem;
}

.admin-payments-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.admin-payments-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.admin-payments-container .admin-payments-body .middle-container {
  height: 100%;
}

.admin-payments-body .title-div {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.5rem;
  margin-bottom: var(--title-margin-bottom);
}

.admin-payments-body .title-div .title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
}

.admin-payments-body .title-div .add-vendor-button {
  color: var(--outside-highlight-color);
  border: var(--button-button-border);
  background: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: calc(0.95rem * var(--font-size-scale));
}

.admin-payments-body .bottom-content {
  animation: fade-in-up 0.6s;
}

.admin-payments-body .div-cards {
  overflow: auto;
  max-height: 80vh;
  margin-top: var(--margin-content-under-tab);
}

.admin-payments-body .div-cards .card-payment {
  margin: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  font-size: calc(0.8rem * var(--font-size-scale));
}

.admin-payments-body .div-cards .selected-card {
  border: none;
  color: var(--selected-text-color);
  background: var(--selected-background-color);
}

.admin-payments-body .div-cards .card-payment .metadata-column {
  display: flex;
  width: 30%;
}

.admin-payments-body .div-cards .card-payment .metadata-column .text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.admin-payments-body .div-cards .card-payment .metadata-column .text .title {
  font-weight: bold;
  font-size: calc(0.95rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.admin-payments-body .div-cards .card-payment .metadata-column .text .date {
  font-size: calc(0.825rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: var(--opacity-2);
}

.admin-payments-body .div-cards .card-payment .status-column {
  text-align: start;
  font-weight: bold;
  width: 20%;
  opacity: var(--opacity-2);
}

.admin-payments-body .div-cards .card-payment .description-column {
  text-align: start;
  width: 30%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  opacity: var(--opacity-2);
}

.admin-payments-body .div-cards .card-payment .amount-column {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 20%;
}

.admin-payments-body .div-cards .card-payment .amount-column .amount {
  font-weight: bold;
  font-size: calc(0.95rem * var(--font-size-scale));
  color: var(--inside-success-color);
}

.admin-payments-body .div-cards .card-payment .amount-column .amount-danger {
  color: var(--inside-danger-color);
}

.admin-payments-body .sort-icons-div {
  margin-left: 0.75rem;
  display: flex;
  flex-direction: column;
}

.admin-payments-body .sort-icons-div .sort-icon {
  opacity: 0.25;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1.025rem;
}

.admin-payments-body .sort-icons-div .sort-icon-picked {
  opacity: 1;
  cursor: pointer;
}

.admin-payments-body .div-cards .icon:hover {
  transform: scale(1.1);
}

.admin-payments-body .card-payment .card-body-skeleton {
  height: 4rem;
  width: 100%;
  background-color: var(--skeleton-background-color);
}

.admin-payments-body .card-payment .metadata-column-skeleton {
  width: 25%;
  display: flex;
  align-items: center;
}

.admin-payments-body .card-payment .metadata-column-skeleton .text-skeleton {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.admin-payments-body
  .card-payment
  .metadata-column-skeleton
  .text-skeleton
  .title-skeleton {
  background-color: var(--skeleton-background-color);
  height: 1rem;
  width: 100%;
  border-radius: var(--div-border-radius);
}

.admin-payments-body
  .card-payment
  .metadata-column-skeleton
  .text-skeleton
  .date-skeleton {
  background-color: var(--skeleton-background-color);
  margin-top: 0.5rem;
  height: 0.7rem;
  width: 80%;
  border-radius: var(--div-border-radius);
}

.admin-payments-body .card-payment .balance-column-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  width: 10%;
}

.admin-payments-body .card-payment .balance-column-skeleton .value-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 1rem;
  width: 100%;
}

.admin-payments-body .card-payment .graph-column-skeleton {
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  height: 100%;
  width: 20%;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
  .admin-payments-body .div-cards {
    max-height: unset;
    margin-top: 0rem;
  }

  .admin-payments-body .div-cards .card-vendor {
    width: 100% !important;
    margin: 0.4em 0rem;
    transition: none;
  }

  .admin-payments-body .div-cards .name-column {
    width: 30%;
  }

  .admin-payments-body .div-cards .name-column .name {
    font-size: 1.1rem;
  }

  .admin-payments-body .div-cards .name-column .region {
  }

  .admin-payments-body .div-cards .metadata-column {
    display: none;
  }

  .admin-payments-body .div-cards .button-column {
    display: flex;
    width: 25%;
  }

  .admin-payments-body .div-cards .account-column {
    width: 25%;
  }

  .admin-payments-body .div-cards .description-column {
    display: none;
  }

  .admin-payments-body .div-cards .paid-with-column {
    display: none;
  }

  .admin-payments-body .div-cards .card-vendor .metadata-column-skeleton {
    width: 43%;
  }

  .admin-payments-body .div-cards .card-vendor .balance-column-skeleton {
    width: 22%;
  }

  .admin-payments-body .div-cards .icons-column {
    width: 4rem;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  :root {
    --padding-cards: 0.75rem;
  }

  .admin-payments-container {
  }

  .admin-payments-container .admin-payments-body .middle-container {
    width: 100%;
  }

  .admin-payments-body .title-div {
    padding: 0rem;
  }

  .admin-payments-body .title-div .add-vendor-button {
    padding-right: 0rem;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
