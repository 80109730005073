.verify-info-container {
  width: 100%;
  display: flex;
  color: var(--outside-div-text-color);
}

.verify-info-container .vendor-form-div {
  width: var(--small-form-width);
  margin: auto;
  display: flex;
  flex-direction: column;
  animation: fade-in-up 0.6s;
}

.verify-info-container .main-title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  margin-bottom: var(--title-margin-bottom);
  text-align: start;
  width: var(--small-form-width);
}

.verify-info-container .react-code-input {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.verify-info-container .resend-message {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.verify-info-container .resend-message b {
  font-weight: bolder;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 1500px) {
}
@media only screen and (max-width: 1200px) {
  .verify-info-container {
  }
  .verify-info-container .middle-container {
    width: 100%;
  }
  .verify-info-container .side-container {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
