.sales-agent-div .generate-pdf-buton {
  width: 100%;
  margin-top: 1rem;
}

.sales-agent-div .download-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 2rem;
}

.sales-agent-div .download-result .icon {
  width: 1.75rem;
  height: 1.75rem;
}

.sales-agent-div .download-result .info {
  margin-top: 0.25rem;
  font-size: calc(0.875rem * var(--font-size-scale));
}

.sales-agent-div .download-result .download-link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: var(--font-weight-3);
  color: var(--outside-highlight-color);
  font-size: calc(0.875rem * var(--font-size-scale));
}

.sales-agent-div .download-result .bottom-info {
  margin-top: 0.25rem;
  font-size: calc(0.85rem * var(--font-size-scale));
  opacity: var(--opacity-2);
  font-weight: 100;
}

.sales-agent-div .choose-start-date-check {
  width: fit-content;
  margin-bottom: 1rem;
}

.sales-agent-div .loader-div {
  margin-top: 2rem;
}

.sales-agent-div .pdf-preview-div {
  border-radius: var(--div-border-radius);
  box-shadow: 0 1.5px 8px rgba(0, 0, 0, 0.2);
  height: 100vh;
  margin-top: 2rem;
}
