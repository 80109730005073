.monthly-payment-report-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.monthly-payment-report-body {
  width: 100%;
  display: flex;
}

.monthly-payment-report-body .title {
  color: var(--outside-div-text-color);
  text-align: start;
  font-size: calc(1.1rem * var(--font-size-scale)) !important;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.monthly-payment-report-body .section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.monthly-payment-report-body .section .left-input {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: var(--div-border-radius);
  border: var(--div-border);
  position: relative;
  animation: fade-in-up 0.6s;
}

.monthly-payment-report-body .generate-pdf-buton {
  margin-top: 1rem;
  width: 100%;
}

.monthly-payment-report-body .section .right-div {
  width: 50%;
  padding: 0rem 1.25rem 0rem 1.25rem;
  display: flex;
  flex-direction: column;
  color: var(--outside-div-text-color);
}

.monthly-payment-report-body .download-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 2rem;
}

.monthly-payment-report-body .download-result .icon {
  width: 1.75rem;
  height: 1.75rem;
}

.monthly-payment-report-body .download-result .info {
  margin-top: 0.25rem;
  font-size: calc(0.875rem * var(--font-size-scale));
}

.monthly-payment-report-body .download-result .download-link {
  cursor: pointer;
  text-decoration: underline;
  font-weight: var(--font-weight-3);
  color: var(--outside-highlight-color);
  font-size: calc(0.875rem * var(--font-size-scale));
}

.monthly-payment-report-body .download-result .bottom-info {
  margin-top: 0.25rem;
  font-size: calc(0.85rem * var(--font-size-scale));
  opacity: var(--opacity-2);
  font-weight: 100;
}

.monthly-payment-report-body .loader-div {
  margin-top: 2rem;
}

.monthly-payment-report-body .pdf-preview-div {
  border-radius: var(--div-border-radius);
  box-shadow: 0 1.5px 8px rgba(0, 0, 0, 0.2);
  height: 100vh;
  margin-top: 2rem;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
  .monthly-payment-report-container
    .monthly-payment-report-body
    .middle-container {
    width: 100%;
  }

  .monthly-payment-report-body .section {
    flex-direction: column;
  }

  .monthly-payment-report-body .section .left-input {
    padding: 1rem;
    width: 100%;
  }

  .monthly-payment-report-body .section .value-input {
    padding: 0rem;
  }

  .monthly-payment-report-body .section .right-div {
    width: 100%;
    padding: 1rem 0rem;
  }

  .monthly-payment-report-body .download-result {
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .monthly-payment-report-container
    .monthly-payment-report-body
    .middle-container {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
