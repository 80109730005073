.plugins-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.plugins-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.plugins-body .title-div {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.5rem;
  margin-bottom: var(--title-margin-bottom);
}

.plugins-body .title-div .title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
}

.plugins-body .title-div .small-button {
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
}

.plugins-container .plugins-body .middle-container {
  height: 100%;
}

.div-plugins-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  animation: fade-in-up 0.6s;
}

.div-plugins-cards .headers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  margin: 0rem 0rem 0.25rem 0rem;
  padding: 0.75rem 1rem;
  font-weight: var(--font-weight-3);
  color: var(--outside-div-text-color);
  font-size: calc(0.9rem * var(--font-size-scale));
}

.div-plugins-cards .plugins-row {
  margin: 0rem 0rem 1rem 0rem;
  transition: all 0.15s ease-in-out;
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div-plugins-cards .status-column {
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-plugins-cards .status-column .status {
  font-size: calc(0.825rem * var(--font-size-scale));
  width: fit-content;
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  height: 2rem;
  cursor: pointer;
  font-weight: var(--font-weight-3);
  transition: all 0.25s ease;
}

.div-plugins-cards .status-column .loading-thing {
  width: fit-content;
}

.div-plugins-cards .status-column .status:hover {
}

.div-plugins-cards .detail-column {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  width: 17.5%;
  min-width: 4rem;
}

.div-plugins-cards .detail-column .description {
  font-size: calc(0.9rem * var(--font-size-scale));
}

.div-plugins-cards .detail-column .date {
  font-size: calc(0.825rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: var(--opacity-2);
}

.div-plugins-cards .payment-methods-column {
  width: 20%;
  min-width: 5rem;
  text-align: start;
  font-size: calc(0.875rem * var(--font-size-scale));
}

.div-plugins-cards .cryptos-column {
  width: 15%;
  min-width: 4rem;
  text-align: start;
  font-size: calc(0.875rem * var(--font-size-scale));
}

.div-plugins-cards .button-column {
  display: flex;
  justify-content: end;
  width: 12rem;
}

.div-plugins-cards .button-column .icon {
  font-size: calc(1.4rem * var(--font-size-scale));
  cursor: pointer;
}

.div-plugins-cards .button-column .icon-disabled {
  opacity: 0.8;
  cursor: auto;
}

.div-plugins-cards .button-column .icon-left {
  margin-right: 1rem;
}

.div-plugins-cards .detail-column-skeleton {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.div-plugins-cards .detail-column-skeleton .description-skeleton {
  width: 75%;
  height: 0.75rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  margin-bottom: 0.5rem;
}

.div-plugins-cards .detail-column-skeleton .date-skeleton {
  width: 50%;
  height: 0.7rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  margin-bottom: 0.5rem;
}

.div-plugins-cards .payment-methods-column-skeleton {
  width: 14%;
  height: 1.05rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.div-plugins-cards .crypto-column-skeleton {
  width: 14%;
  height: 1.05rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.div-plugins-cards .status-column-skeleton {
  width: 5rem;
  height: 1.15rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.div-plugins-cards .button-column-skeleton {
  width: 9rem;
  display: flex;
  justify-content: end;
}

.div-plugins-cards .button-column-skeleton .icon-skeleton {
  width: 1.4rem;
  height: 1.4rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.div-plugins-cards .button-column-skeleton .icon-left {
  margin-right: 1rem;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .plugins-container .plugins-body .middle-container {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
