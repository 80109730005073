:root {
}

.plugin-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.plugin-body {
  height: 100%;
  width: 100%;
  display: flex;
}

.plugin-container .plugin-body .middle-container {
  height: 100%;
}

.plugin-body .search-plugin-detail {
  margin-top: 1rem;
}

.plugin-body .title-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--title-margin-bottom);
  line-height: 1.5rem;
}

.plugin-body .title-div .title {
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: bold;
  color: var(--outside-div-text-color);
  text-align: start;
}

.plugin-body .title-div .plugin-button {
  color: var(--outside-highlight-color);
  border: var(--button-button-border);
  background: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plugin-body .bottom-content {
  animation: fade-in-up 0.6s;
}

.plugin-body .div-cards-container {
  margin-top: var(--margin-content-under-tab);
}

.plugin-body .div-cards-container .div-cards {
  overflow: auto;
  max-height: 80vh;
}

.plugin-body .div-cards-container .div-cards .card-plugin {
  margin: 0rem 0rem 1rem 0rem;
  transition: all 0.15s ease-in-out;
  height: 7rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: calc(0.85rem * var(--font-size-scale));
}

.plugin-body .div-cards-container .div-cards .selected-card {
  border: none;
  color: var(--selected-text-color);
  background: var(--selected-background-color);
}

.plugin-body .div-cards-container .headers {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  margin: 0rem 0rem 0.5rem 0rem;
  padding: 0.75rem 1rem;
  font-weight: bold;
  color: var(--outside-div-text-color);
  font-size: calc(0.9rem * var(--font-size-scale));
}

.plugin-body .div-cards-container .payer-info-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 18%;
  overflow: hidden;
  text-align: start;
}

.plugin-body .div-cards-container .payer-info-column .name {
  font-weight: bold;
  font-size: calc(0.95rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.plugin-body .div-cards-container .payer-info-column .email {
  font-size: calc(0.825rem * var(--font-size-scale));
  width: 100%;
  text-align: start;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: var(--opacity-2);
}

.plugin-body .div-cards-container .currency-column {
  width: 10%;
  text-align: center;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  opacity: var(--opacity-2);
}

.plugin-body .div-cards-container .amount-column {
  width: 15%;
  text-align: center;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.plugin-body .div-cards-container .payment-method-column {
  width: 15%;
  text-align: center;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  opacity: var(--opacity-2);
}

.plugin-body .div-cards-container .status-column {
  width: 12.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  height: 2rem;
  text-transform: capitalize;
}

.plugin-body .div-cards-container .date-column {
  width: 20%;
  text-align: center;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  opacity: var(--opacity-2);
  font-size: calc(0.8rem * var(--font-size-scale));
}

.plugin-body .div-cards-container .icon:hover {
  transform: scale(1.1);
}

.plugin-body .div-cards-container .payer-info-column-skeleton {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.plugin-body .div-cards-container .payer-info-column-skeleton .name {
  width: 50%;
  height: 0.75rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
  margin-bottom: 0.5rem;
}

.plugin-body
  .div-cards-container
  .div-cards
  .payer-info-column-skeleton
  .email {
  width: 75%;
  height: 0.7rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.plugin-body .div-cards-container .currency-column-skeleton {
  width: 7.5%;
  height: 0.75rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.plugin-body .div-cards-container .amount-column-skeleton {
  width: 10%;
  height: 0.75rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.plugin-body .div-cards-container .payment-method-column-skeleton {
  width: 10%;
  height: 0.75rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.plugin-body .div-cards-container .status-column-skeleton {
  width: 12.5%;
  height: 2rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.plugin-body .div-cards-container .date-column-skeleton {
  width: 15%;
  height: 0.725rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.plugin-body .div-cards-container .icons-column-skeleton {
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--skeleton-background-color);
  border-radius: var(--div-border-radius);
}

.plugin-body .plugin-detail-div {
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  background: var(--div-background);
  color: var(--inside-div-text-color);
  margin: 0rem 1rem;
  padding: 1rem;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: fade-in-up 0.6s;
  margin-top: calc(var(--title-margin-bottom) + 2.25rem + 0.6rem);
}

.plugin-body .plugin-detail-div .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plugin-body .plugin-detail-div .header .title {
  font-weight: bold;
  font-size: 1.1rem;
}

.plugin-body .plugin-detail-div .header .close-icon {
  cursor: pointer;
  font-size: 1.5rem;
}

.plugin-body .plugin-detail-div .body {
  padding: 1rem 0;
  font-style: italic;
}

.detail-container {
  display: flex;
  flex-direction: column;
}

.detail-container .icons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 8rem;
  font-size: calc(2.2rem * var(--font-size-scale));
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.detail-container .icons-row .left-icon {
  cursor: pointer;
}

.detail-container .icons-row .right-icon {
  cursor: pointer;
}

.detail-container .icons-row .loader-icon {
  width: calc(2.5rem * var(--font-size-scale));
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-detail-footer a {
  font-weight: bold;
  color: var(--outside-highlight-color);
  text-decoration: none;
}

.plugin-container .plugin-body .settings-div {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0rem 1rem;
}

.plugin-container .plugin-body .settings-sub-section-div {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-bottom: 1rem;
}

.plugin-container .plugin-body .settings-sub-section-div .sub-section-title {
  font-weight: var(--font-weight-3);
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  color: var(--outside-div-text-color);
  margin-bottom: 0.25rem;
}

.plugin-container .plugin-body .settings-sub-section-div .sub-section-body {
  margin-top: 0.5rem;
}

.plugin-container .plugin-body .settings-sub-section-div .loader {
  margin: auto;
  display: flex;
}

.plugin-container .html-schema-form-div {
  display: flex;
  justify-content: space-between;
}

.plugin-container .html-schema-form-div .form-control {
  width: calc(50% - 0.5rem - 4rem);
}

.plugin-container .html-schema-form-div .outside-primary-button {
  width: 4rem;
}

.plugin-container .html-schema-form-div .html-schema-row-icon {
  cursor: pointer;
  width: 4rem;
  font-size: calc(1.5rem * var(--font-size-scale));
}

.plugin-container .invoice-after-payment-check {
  margin-top: 0.5rem;
}

.plugin-container .settings-div .save-buttons-div {
  display: flex;
  justify-content: end;
}

.plugin-container .settings-div .save-buttons-div .back-button {
  margin-right: 1rem;
}

.status-form-div {
  text-align: start;
}

.status-form-div .status {
  font-size: calc(0.825rem * var(--font-size-scale));
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  height: 2rem;
  cursor: pointer;
  font-weight: var(--font-weight-3);
  transition: all 0.25s ease;
}

.plugin-container .copy-key-icon {
  margin-left: 0.25rem;
  font-size: calc(1.15rem * var(--font-size-scale)) !important;
}

.plugin-container .copy-success-icon {
  margin-left: 0.35rem;
  font-size: calc(1.15rem * var(--font-size-scale)) !important;
  animation: animate-fade 0.25s;
}

.plugin-container .form-check label {
  margin-left: 1rem;
  color: var(--outside-div-text-color);
}

@media only screen and (max-width: 1500px) {
  .plugin-body .side-container {
    width: 20%;
  }

  .plugin-body .middle-container {
    width: 60%;
  }
}

@media only screen and (max-width: 1200px) {
  .plugin-body .middle-container {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 450px) {
  :root {
    --padding-cards: 0.75rem;
  }

  .plugin-container {
  }

  .plugin-body {
  }

  .plugin-container .plugin-body .middle-container {
    width: 100%;
  }

  .plugin-body .title-div .title {
    text-align: start;
  }

  .plugin-body .title-div .plugin-button {
    padding-right: 0rem;
    text-align: end;
  }

  .plugin-body .div-cards-container {
    max-height: unset;
    margin-top: 0.5rem;
  }

  .plugin-body .div-cards-container .card-plugin {
    width: 100% !important;
    margin: 0.4em 0rem;
    transition: none;
  }

  .plugin-body .small-button {
    width: 100%;
    margin-top: 1rem;
  }

  .detail-container .detail-row {
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }

  .detail-container .detail-row .value {
    text-align: end;
  }

  .plugin-body .div-cards-container .icons-column {
    width: 4rem;
  }
}
