.notifications-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  /*margin-top: calc(var(--total-balance-div) + var(--filter-height) + 1rem);*/
}

.notifications-div .notification-card {
  width: 70%;
  aspect-ratio: 450/284;
  border-radius: var(--div-border-radius);
  color: var(--notification-card-text-color);
  border: var(--notification-card-border);
  font-weight: 550;
  display: flex;
  margin-bottom: 1rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: var(--div-shadow-border);
  font-size: calc(1rem * var(--font-size-scale));
  text-decoration: none;
  /*  animation: fade-in-up 0.6s;*/
}

.notifications-div .notification-card:hover {
  box-shadow: var(--div-shadow-border-hover);
}

.notifications-div .notification-card .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 1rem;
  z-index: 10;
  text-align: start;
}

.notifications-div .notification-card .text .higlight-text {
  color: var(--notification-card-highlight-color);
  font-weight: bold;
}

.notifications-div .notification-card .image {
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.notifications-div .notification-card .image-src {
  background: var(--notification-cards-background);
}

.notifications-div .notification-card .text .scroller {
  width: 10rem;
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  text-align: left;
}

.notifications-div .notification-card .text .scroller > span {
  top: 0;
  position: absolute;
  animation: vertical-scroll 7s infinite;
  color: var(--notification-card-highlight-color);
  font-weight: bold;
}

@media only screen and (max-width: 1700px) {
  .notifications-div .notification-card {
    width: 75%;
  }
}

@media only screen and (max-width: 1600px) {
  .notifications-div .notification-card {
    width: 80%;
  }
}

@media only screen and (max-width: 1500px) {
  .notifications-div .notification-card {
  }
}

@media only screen and (max-width: 1400px) {
  .notifications-div .notification-card {
    width: 70%;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
