.see-conversion-link-container .see-conversion-link {
  background: none !important;
  color: var(--outside-highlight-color) !important;
  border: none !important;
  font-weight: bold !important;
  transition: none !important;
  text-decoration: none;
  display: flex;
  justify-content: end;
  /* margin-top: 0.5rem; */
  margin-bottom: 1rem;
  font-size: calc(0.95rem * var(--font-size-scale)) !important;
  cursor: pointer;
}

.see-conversion-link-modal .modal-content .modal-body {
  padding: 0.25rem !important;
}

.see-conversion-component-div .conversion-component-container {
  margin-top: 0rem;
  animation: none !important;
}

.see-conversion-component-div .conversion-rate-detail-container {
  animation: none !important;
}

@media only screen and (max-width: 900px) {
  .see-conversion-component-div .conversion-rate-detail-container {
    margin: 1rem 0rem 0rem 0rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
