:root {
}

.deposit-withdraw-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
}

.deposit-withdraw-body {
  width: 100%;
  display: flex;
}

.deposit-withdraw-body .tab-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.deposit-withdraw-body .top-section {
  width: 100%;
  display: flex;
}

.deposit-withdraw-body .bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  animation: fade-in-up 0.6s;
}

.deposit-withdraw-body .top-section .form-container {
  width: 50%;
  /*height: 50%;*/
  height: fit-content;
  animation: fade-in-up 0.6s;
  border-radius: var(--div-border-radius);
  border: var(--div-border);
  position: relative;
}

.deposit-withdraw-body .top-section .form-container .background {
  background-color: var(--div-background);
  border-radius: var(--div-border-radius);
  opacity: var(--div-opacity);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.deposit-withdraw-body .top-section .form-container .content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  border-radius: var(--div-border-radius);
  z-index: 1;
  position: relative;
}

.deposit-withdraw-body .form-to {
  background-color: transparent;
  border: 1px solid transparent !important;
}

.deposit-withdraw-body .top-section .form-container .form-section-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  border-radius: var(--div-border-radius);

  align-items: center;
}

.deposit-withdraw-body .info-div {
  width: 100%;
  background-color: transparent;
}

.deposit-withdraw-body
  .top-section
  .form-container
  .form-section-div
  .form-div {
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 1rem;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method {
  width: 100%;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .bar {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  margin-left: 0 !important;
  color: var(--inside-input-text-color);
  border-radius: var(--inside-input-radius) !important;
  border: var(--inside-input-border);
  background: var(--inside-input-background);
  cursor: pointer;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .no-cursor {
  cursor: default !important;
}
.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .outside {
  color: var(--outside-input-text-color);
  border-radius: var(--outside-input-radius) !important;
  border: var(--outside-input-border);
  background: var(--outside-input-background);
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .bar
  .search-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.75rem;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .bar
  .text {
  margin-left: 1rem;
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .bar
  .text
  .value-in-blox {
  margin-left: 0.4rem;
  font-size: 0.9rem;
  opacity: 0.85;
  line-height: 1rem;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .bar
  .browse-button {
  width: 7rem;
  height: 100%;
  background-color: #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 var(--inside-input-radius) var(--inside-input-radius) 0;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .bar
  .icon {
  font-size: 2rem;
  background-color: white;
  margin-left: 0.75rem;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .bar
  .eliminate-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
}

.deposit-withdraw-body
  .form-container
  .form-section-div
  .form-div
  .input-payment-method
  .currency {
  width: 50%;
  background-color: white;
  border-radius: var(--div-border-radius);
  border: none;
  display: flex;
  justify-content: end;
}

.deposit-withdraw-body
  .top-section
  .form-container
  .form-section-div
  .text-detail {
  color: var(--inside-div-text-color);
  margin-top: auto;
  font-size: 0.9rem;
  font-style: italic;
}

.deposit-withdraw-body .info-div .exchange-rate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  color: var(--outside-div-text-color);
  margin-bottom: 0.5rem;
}

.deposit-withdraw-body .info-div .info-table {
  border-radius: var(--div-border-radius);
  border: var(--outside-highlight-color) 1px solid;
  color: var(--outside-div-text-color);
  padding: 1rem;
  align-items: center;
}

.deposit-withdraw-body .info-div .info-table .data-row {
  height: fit-content;
  color: var(--outside-div-text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.deposit-withdraw-body .deposit-list-container {
  width: 50%;
  padding: 0rem 1.25rem 0 1.25rem;
  border-radius: var(--div-border-radius);
  overflow: auto;
  animation: fade-in-up 0.6s;
}

.deposit-withdraw-body .deposit-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: var(--div-border-radius);
  align-items: center;
  overflow: auto;
  color: var(--outside-div-text-color);
}

.deposit-withdraw-body .deposit-list ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.deposit-withdraw-body .deposit-list ::-webkit-scrollbar-thumb {
  background: #888;
}

.deposit-withdraw-body .deposit-list ::-webkit-scrollbar-track {
  background: white;
}

.deposit-withdraw-body .deposit-list .deposit-row-container {
  width: 100%;
  border-radius: var(--div-border-radius);
  border: var(--div-border);
  cursor: pointer;
  margin-bottom: 1rem;
}

.deposit-withdraw-body .deposit-list .deposit-row {
  width: 100%;
  height: 6rem;
  border-radius: var(--div-border-radius);
  position: relative;
}

.deposit-withdraw-body .deposit-list .deposit-row .background {
  width: 100%;
  height: 100%;
  border-radius: var(--div-border-radius);
  background-color: var(--div-background);
  opacity: var(--div-opacity);
  position: absolute;
  z-index: 0;
}

.deposit-withdraw-body .deposit-list .deposit-row .content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: var(--div-border-radius);
  color: var(--inside-div-text-color);
  position: relative;
  z-index: 1;
}

.deposit-withdraw-body .deposit-list .deposit-row .metadata-column {
  width: 60%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.deposit-withdraw-body .deposit-list .deposit-row .metadata-column .icon {
  font-size: 2.5rem;
  margin-right: 0.75rem;
  color: var(--inside-highlight-color);
}

.deposit-withdraw-body .deposit-list .deposit-row .metadata-column .title {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: start;
}

.deposit-withdraw-body .deposit-list .deposit-row .metadata-column .region {
  font-size: 0.9rem;
  font-style: italic;
  text-align: start;
}

@media only screen and (max-width: 1500px) {
}
@media only screen and (max-width: 1200px) {
  .deposit-withdraw-container {
    margin-bottom: 0rem;
  }

  .deposit-withdraw-body
    .form-container
    .form-section-div
    .form-div
    .input-payment-method
    .bar
    .text {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  :root {
    --padding-cards: 0.75rem;
  }

  .deposit-withdraw-container .deposit-withdraw-body .go-back {
    display: flex !important;
  }

  .deposit-withdraw-container .deposit-withdraw-body .middle-container {
    width: 100%;
  }

  .tab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs {
    justify-content: center;
  }

  .deposit-withdraw-body .tab-content {
  }

  .deposit-withdraw-body .tab-container {
    flex-direction: column;
    margin-top: 1rem;
  }

  .deposit-withdraw-body .top-section .form-container {
    width: 100%;
    margin-bottom: 0rem;
  }

  .deposit-withdraw-body .top-section .form-container .content {
    padding: var(--padding-cards);
  }

  .deposit-withdraw-body .top-section .form-container .form-section-div {
    height: 100%;
  }

  .deposit-withdraw-body
    .form-container
    .form-section-div
    .form-div
    .input-payment-method {
    width: 100%;
  }

  .deposit-withdraw-body
    .form-container
    .form-section-div
    .form-div
    .input-payment-method
    .bar {
    font-size: 0.9rem;
  }

  .deposit-withdraw-body .info-div {
    width: 100%;
  }

  .tab .nav-tabs .nav-item.show .nav-link,
  .nav-tabs {
    justify-content: center;
  }

  .deposit-withdraw-body .info-div .info-table .data-row {
    height: 12.5%;
  }

  .deposit-withdraw-body .deposit-withdraw-button {
    width: 100%;
  }

  .deposit-withdraw-body .deposit-list-container {
    width: 100%;
    padding: 0rem;
    margin-top: 1.5rem;
  }

  .deposit-withdraw-body .deposit-list .deposit-row-container {
    padding: 0;
    height: 6rem;
  }

  .deposit-withdraw-body .top-section {
    flex-direction: column;
  }

  .deposit-withdraw-body .bottom-section {
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
