.layout-footer {
  width: 100%;
  height: var(--footer-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 6rem;
  margin-top: 5rem;
  background: var(--footer-background-color);
  color: var(--footer-text-color);
  opacity: var(--footer-opacity);
}

.layout-footer .title {
  font-size: calc(1.15rem * var(--font-size-scale));
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 0.5rem;
}

.layout-footer .logo-div {
  width: 20%;
}

.layout-footer .follow_us_at {
  width: 20%;
}

.layout-footer .follow_us_at .icons {
  width: 55%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.layout-footer .follow_us_at .icons a {
  margin-right: 0.25rem;
  font-size: calc(1.75rem * var(--font-size-scale));
  color: var(--footer-text-color);
}

.layout-footer .follow_us_at .icons .tiktok {
  font-size: calc(1.45rem * var(--font-size-scale));
}

.layout-footer .go_to {
  width: 20%;
}

.layout-footer .go_to .links-div {
  display: flex;
  flex-direction: column;
}

.layout-footer .go_to .links-div a {
  color: var(--footer-text-color) !important;
  text-decoration: none;
  font-size: calc(0.9rem * var(--font-size-scale));
}

.layout-footer .terms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(1rem * var(--font-size-scale));
}

.layout-footer .terms a {
  color: var(--footer-text-color) !important;
  width: fit-content;
  text-decoration: none;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-footer .top-row {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: space-around;
}

.layout-footer .top-row .logo-div .logo {
  width: var(--logo-footer-width);
  aspect-ratio: var(--logo-aspect-ratio);
  cursor: pointer;
}

.layout-footer .top-row .logo-div .title {
  width: 75px;
  display: var(--footer-title-display);
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: calc(1.5rem * var(--font-size-scale));
  margin-top: 0.25rem;
  cursor: pointer;
}

.layout-footer .bottom-row {
  font-size: calc(0.75rem * var(--font-size-scale));
  line-height: 1rem;
  opacity: 75%;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1200px) {
  .layout-footer {
    height: fit-content;
  }
}

@media only screen and (max-width: 900px) {
  .layout-footer {
    padding: 1.5rem 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .layout-footer {
    padding: 1.5rem;
  }

  .layout-footer .top-row {
    flex-wrap: wrap;
  }

  .layout-footer .top-row .logo-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .layout-footer .top-row .logo-div .logo {
    width: 4rem;
    aspect-ratio: 134/150;
  }

  .layout-footer .title {
    font-size: calc(1.25rem * var(--font-size-scale));
  }

  .layout-footer .follow_us_at {
    width: 50%;
  }

  .layout-footer .go_to {
    width: 50%;
  }

  .layout-footer .terms {
    margin-top: 1rem;
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
