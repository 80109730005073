.withdraw-container {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.withdraw-body {
  width: 100%;
  display: flex;
}

.withdraw-body .withdraw-title {
  color: var(--outside-div-text-color);
  text-align: start;
  font-size: calc(1.1rem * var(--font-size-scale)) !important;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.withdraw-body .loading-currencies {
  margin-top: 5rem;
}

.withdraw-body .loading-conversions {
  margin-bottom: 1rem !important;
  width: 2rem !important;
  height: 2rem !important;
}

.withdraw-body .section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.withdraw-body .section .left-input {
  width: 50%;
  height: fit-content;
  animation: fade-in-up 0.6s;
}

.withdraw-body .section .left-input .form-div {
  height: fit-content;
  width: 100%;
}

.withdraw-body .section .left-input .form-div .danger {
  color: var(--inside-danger-color);
}

.withdraw-body .section .left-input .message-div {
  color: var(--inside-div-text-color);
  font-style: italic;
  font-size: calc(0.85rem * var(--font-size-scale)) !important;
  margin-top: 2rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: end;
}

.withdraw-body .section .left-input-no-height {
  height: unset;
}

.withdraw-body .section .options-div {
  width: 100%;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  color: var(--outside-div-text-color);
  animation: fade-in-up 0.6s;
}

.withdraw-body .section .options-div .sub-title {
  text-align: start;
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  /* font-style: italic; */
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  display: none;
}

.withdraw-body .section .right-div {
  width: 50%;
  padding: 0rem 1.25rem 0rem 1.25rem;
  display: flex;
  flex-direction: column;
  color: var(--outside-div-text-color);
}

.withdraw-body .section .input-group .text-area {
  margin-bottom: 1rem;
}

.withdraw-body .section .right-div .text-area {
  height: 4rem;
}

.withdraw-body .section .value-input {
  width: 100%;
  height: 100%;
  padding: 1.25rem 0 0 0;
  animation: fade-in-up 0.6s;
  display: flex;
  flex-direction: column;
}

.withdraw-body .loader {
  width: 4rem !important;
  height: 4rem !important;
}

.withdraw-body .error-form-text {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
  color: crimson;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  animation: bounce 0.5s;
}

.withdraw-body .withdraw-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: var(--div-border-radius);
  align-items: center;
  overflow: auto;
  color: var(--outside-div-text-color);
  animation: fade-in-up 0.6s;
}

.withdraw-body .withdraw-list ::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.withdraw-body .withdraw-list ::-webkit-scrollbar-thumb {
  background: #888;
}

.withdraw-body .withdraw-list ::-webkit-scrollbar-track {
  background: white;
}

.withdraw-body .withdraw-list .withdraw-row-container {
  width: 100%;
  height: 6rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.withdraw-body .withdraw-list .arrow {
  color: var(--inside-div-text-color);
  font-size: calc(var(--arrow-icon-size) * var(--font-size-scale)) !important;
}

.withdraw-body .withdraw-list .metadata-column {
  width: calc(100% - 3rem);
  display: flex;
  justify-content: start;
  align-items: center;
}

.withdraw-body .withdraw-list .metadata-column .icon {
  font-size: calc(1.85rem * var(--font-size-scale)) !important;
  margin-right: 0.75rem;
  color: var(--inside-highlight-color);
}

.withdraw-body .withdraw-list .metadata-column .option-name {
  font-size: calc(0.95rem * var(--font-size-scale)) !important;
  font-weight: var(--font-weight-3);
  text-align: start;
}

.withdraw-body
  .withdraw-list
  .withdraw-row
  .metadata-column
  .option-description {
  font-size: calc(0.9rem * var(--font-size-scale)) !important;
  font-style: italic;
  text-align: start;
  opacity: var(--opacity-2);
}

.withdraw-body .withdraw-list .metadata-column .region {
  font-size: 0.9rem;
  font-style: italic;
  text-align: start;
}

.withdraw-body .bottom-section {
  animation: fade-in-up 0.6s;
  margin-top: 1rem;
}

.withdraw-body .withdraw-cash-result-div {
  margin: auto;
  width: var(--login-div-width);
  color: var(--outside-div-text-color);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  text-align: center;
  animation: fade-in-up 0.6s;
}

.withdraw-body .withdraw-cash-result-div .withdraw-cash-result-icon {
  font-size: 5rem;
  color: var(--outside-highlight-color);
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.withdraw-body .withdraw-cash-result-div .withdraw-cash-result-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
}

.withdraw-body .withdraw-cash-result-div .withdraw-cash-result-data-div {
  display: flex;
  flex-direction: column;
}

.withdraw-body .withdraw-cash-result-div .withdraw-cash-result-data-row {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.withdraw-body .withdraw-cash-result-div .continue-button {
  margin-top: 1.75rem;
  width: 100%;
  height: 3.5rem;
}

.withdraw-body .auth-div {
  color: var(--inside-div-text-color);
  padding: 0.5rem;
  font-size: 0.9rem;
  flex-grow: 1;
}

.withdraw-body .auth-div b {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 1500px) {
  .withdraw-body .withdraw-list .withdraw-row-container {
    height: 5.5rem;
  }
}

@media only screen and (max-width: 1300px) {
  .withdraw-body .withdraw-list .withdraw-row-container {
    height: 5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .withdraw-container {
    padding: 0rem 0.75rem;
  }

  .withdraw-container .withdraw-body .middle-container {
    width: 100%;
  }

  .withdraw-body .section {
    flex-direction: column;
  }

  .withdraw-body .section .left-input {
    width: 100%;
  }

  .withdraw-body .section .left-input {
    padding: 1rem;
  }

  .withdraw-body .section .value-input {
    padding: 0rem;
  }

  .withdraw-body .section .left-input {
  }

  .withdraw-body .section .right-div {
    width: 100%;
    padding: 1rem 0rem;
  }

  .withdraw-body .buttons-div {
    width: 100%;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  .withdraw-body .buttons-div .continue-button {
    margin-top: 0rem;
    width: 100%;
  }

  .withdraw-body .buttons-div .cancel-button {
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

@media only screen and (max-width: 900px) {
  .withdraw-body .withdraw-list {
    height: 6rem;
  }
}

@media only screen and (max-width: 600px) {
  .withdraw-container {
    padding: 0rem 0.75rem;
  }

  .withdraw-container .withdraw-body .middle-container {
    width: 100%;
  }

  .withdraw-body .section {
    flex-direction: column;
  }

  .withdraw-body .section .left-input {
    width: 100%;
  }

  .withdraw-body .section .left-input {
    padding: 1rem;
  }

  .withdraw-body .section .value-input {
    padding: 0rem;
  }

  .withdraw-body .section .left-input {
  }

  .withdraw-body .section .right-div {
    padding: 1rem 0rem;
  }

  .withdraw-body .buttons-div {
    width: 100%;
    flex-direction: column;
    padding: 0rem 1rem;
    margin-top: 2rem;
  }

  .withdraw-body .buttons-div .continue-button {
    margin-top: 0rem;
    width: 100%;
  }

  .withdraw-body .buttons-div .cancel-button {
    margin-top: 0rem;
    width: 100%;
    margin-bottom: 0.75rem;
  }

  .withdraw-body .withdraw-list .withdraw-row-container {
    padding: 0;
    height: 6rem;
  }

  .withdraw-body .withdraw-list {
    height: 5.5rem;
  }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 320px) {
}
