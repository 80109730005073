:root {
  --register-div-padding: 2rem;
}

.register-container {
  min-height: 120vh;
  width: 100%;
  display: flex;
}

.register-container .middle-center-2 {
  margin: auto;
  margin-top: 6rem;
  padding: var(--register-div-padding);
  box-shadow: var(--div-shadow-border);
  border: var(--div-border);
  border-radius: var(--div-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 700px;
  background: var(--div-background);
  animation: fade-in-up 0.6s;
}

.register-container .middle-center-2 .register-title {
  display: block;
  font-size: calc(var(--page-title-font-size) * var(--font-size-scale));
  font-weight: 650;
  text-align: center;
  margin-bottom: 24px;
  color: var(--inside-div-text-color);
}

.register-container .middle-center-2 .register-subtitle {
  height: 50px;
  display: flex;
  font-size: 1rem;
  font-weight: bolder;
  text-align: center;
  color: var(--inside-div-text-color);
  text-decoration: underline;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.register-container .middle-center-2 .register-image {
  height: 73px;
  width: 73px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.register-container p {
  margin-top: 16px;
  margin-bottom: 0px;
  font-size: 16px;
  color: var(--inside-div-text-color);
  text-align: center;
}

.register-buttons-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

.register-buttons-div .register-button {
  margin-top: 24px;
  width: 100%;
  height: 50px;
}

.register-buttons-div .secondary-button {
  margin-top: 24px;
  width: 100%;
  height: 50px;
}

.div-section3 {
  margin-top: 32px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: var(--inside-div-text-color);
  align-items: center;
  border-bottom: 2px solid var(--inside-div-text-color);
  margin-bottom: 16px;
}

.div-section3 h5 {
  font-size: 18px;
}

.security-verification-div {
  color: var(--inside-div-text-color);
}

.security-verification-div .title {
  font-size: 1.25rem;
  font-weight: bold;
}

.security-verification-div .sub-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.05rem;
}

.security-verification-div .input-description {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.security-verification-div .resend-button {
  border: 1px solid var(--inside-div-text-color);
  color: var(--inside-div-text-color);
  background: none;
}

.security-verification-div .resend-button:focus {
  border: 1px solid var(--inside-div-text-color);
  color: ar(--inside-div-text-color);
  background: none;
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1200px) {
  :root {
    --register-div-padding: 1rem;
  }

  .register-container {
  }

  .register-container .middle-center-2 {
    width: 75%;
    height: unset;
    background: none;
  }

  .register-container .middle-center-2 .register-title {
    color: var(--outside-div-text-color);
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .register-container {
    height: unset;
  }

  .register-container .middle-center-2 {
    box-shadow: none !important;
    border: none !important;
    width: 100%;
    height: unset;
    padding: 1rem;
    background: none;
    margin-top: 6rem;
  }

  .register-container .middle-center-2 .register-title {
    color: var(--outside-div-text-color);
  }
}

@media only screen and (max-width: 400px) {
  .register-container .middle-center-2 {
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 320px) {
  .register-container .middle-center-2 .register-title {
    margin-bottom: 0.75rem;
  }
}

/*so not to overlap region/lang buttons*/
@media only screen and (max-height: 900px) {
  .mb-3 {
    margin-bottom: 0.5rem !important;
  }
  .register-container {
  }

  .register-container .middle-center-2 {
  }
}
